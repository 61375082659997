/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { I18nextProvider } from "react-i18next";
import { CssBaseline, ThemeProvider } from "@mui/material";

import i18n from "./i18n";
import lightTheme from "./themes/lightTheme";
import Router from "./router";
import { fetchWebSiteContent } from "./payload/data";

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <I18nextProvider i18n={i18n} defaultNS="common">
        <Router />
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
