/* eslint-disable prettier/prettier */
import { Typography } from "@mui/material";

function SpecificsForLoyaltyContracts() {
    return (
        <Typography>
            <strong>10. Especificidades para contratos de Fidelidade</strong>
            {/* <span style={{ backgroundColor: "#ff0" }}>
            </span> */}
            <br />

            <span className="hidden" style={{ backgroundColor: "#ff0" }}>
                <strong>10.1.</strong> O CLIENTE declara ter ciência de que em função do recebimento dos benefícios, descritos no REGULAMENTO/FORMULÁRIO DE PEDIDO da oferta BIZPIK, deverá prazo de 6 (seis) ou 12 (doze) meses (ou outro período especificado em formulário de pedido) de “PERMANÊNCIA MÍNIMA” (conforme selecionado no momento da contratação), contados da ATIVAÇÃO do serviço.
                <br />
                <br />
                <span style={{ backgroundColor: "#ff0" }}>
                    <strong>10.2.</strong> Na hipótese de cancelamento do serviço durante o prazo de
                    PERMANÊNCIA MÍNINA, o CLIENTE perderá os descontos concedidos e
                    estará obrigado ao pagamento de multa por rescisão antecipada
                    do contrato no valor referente a 20% (vinte por cento) do valor
                    total do contrato
                </span>
                <br />
                <br />

                <strong>10.2.1.</strong> Os valores devidos serão cobrados pelo BIZPIK, em uma única
                parcela, mediante envio de boleto bancário, e o não pagamento
                deste ensejará o registro do CLIENTE nos CADASTROS DE PROTEÇÃO
                AO CRÉDITO.

                <br />
                <br />
            </span>
            <strong>10.1.</strong> Na hipótese de redução ou alteração para plano inferior ao
            inicialmente contratado durante o prazo de PERMANÊNCIA MÍNIMA, será
            considerada quebra do vínculo de permanência e o CLIENTE estará
            sujeito ao pagamento de multa, conforme CLÁUSULA 10.2. acima
            <br />
            <br />

            <strong>10.2.</strong> Em caso de transferência de titularidade do Contrato, o futuro
            CLIENTE deverá obrigar-se a cumprir todas as estipulações
            referentes a presente contratação, incluindo o período de
            PERMANÊNCIA MÍNINA restante.
            <br />
            <br />
            <strong>10.3.</strong> O Cliente reconhece que lhe foi dada a oportunidade de
            contratar a plataforma disponibilizada pelo BIZPIK sem o prazo
            <br />
            <br />

            <strong>10.4.</strong> Aplica-se o disposto nos outros capítulos e cláusulas deste
            instrumento para contratos de fidelidade, apenas quando não
            conflitar com o disposto neste capítulo de número 5.
            <br />
            <br />
        </Typography>
    );
}

export default SpecificsForLoyaltyContracts;
