/* eslint-disable prettier/prettier */
import { Typography } from "@mui/material";

function MiscellaneousSuppliers() {
  return (
    <Typography>
      <strong>9. Fornecedores diversos do BIZPIK</strong>
      <br />
      <strong>9.1.</strong>
      <a href="http://www.BIZPIK.com/" target="_blank" rel="noreferrer">
        Aquisição de Produtos e plataforma não Fornecidos pelo BIZPIK.
        O BIZPIK ou terceiros poderão, periodicamente, disponibilizar ao
        CLIENTE (p.ex. por meio do http://www.BIZPIK.com)
      </a> produtos e plataforma fornecidos por
      terceiros, incluindo, mas não se limitando a aplicativos,
      implementação, customização ou outras plataformas de consultoria.
      Quaisquer aquisições pelo CLIENTE de produtos ou plataforma não
      fornecidos pelo BIZPIK, bem como a troca de informações entre o
      CLIENTE e os respectivos fornecedores destes produtos e
      plataforma, dar-se-á exclusivamente entre estes. O BIZPIK não
      concede garantia ou suporte de produtos ou plataforma fornecidos
      por terceiros, sejam ou não designados como certificados pelo
      BIZPIK. Sujeito ao disposto na cláusula 10.3 abaixo “Integração
      com Aplicativos não Fornecidos pelo BIZPIK”. O BIZPIK ressalta que
      não se faz necessária a aquisição de qualquer aplicativo fornecido
      por terceiros para a utilização da plataforma, salvo: dispositivo
      de informática compatível, sistema operacional, navegador e
      conexão à internet.
      <br />
      <br />
      <strong>9.2.</strong> Aplicativos não fornecidos pelo BIZPIK e os Dados do CLIENTE. Caso
      o CLIENTE instale ou habilite aplicativos não fornecidos pelo
      BIZPIK, declara-se ciente de que o BIZPIK poderá permitir aos
      fornecedores dos referidos aplicativos, acesso aos dados do
      CLIENTE, conforme necessário para a operação conjunta e suporte
      destes em relação à plataforma. O BIZPIK não será responsabilizado
      por qualquer divulgação, modificação ou exclusão dos dados do
      CLIENTE resultantes da concessão de acesso à terceiros
      fornecedores dos referidos aplicativos. A plataforma deverá
      permitir ao CLIENTE que restrinja o acesso aos seus dados, por
      meio da restrição aos Usuários da instalação ou habilitação de
      aplicativos não fornecidos pelo BIZPIK para uso conjunto com a plataforma.
      <br />
      <br />
      <strong>9.3.</strong> Integração com Funcionalidades não Fornecidas pelo BIZPIK. A
      plataforma poderá conter aspectos desenvolvidos para operar
      conjuntamente com aplicativos não fornecidos pelo BIZPIK (p.ex.
      aplicativos Facebook ou Twitter). Para utilizá-los, poderá ser
      exigido que o CLIENTE obtenha acesso aos mesmos por meio de seus
      respectivos fornecedores. Caso o fornecedor de quaisquer destes
      aplicativos interrompa a disponibilização do mesmo para operar em
      conjunto com as referidas particularidades da plataforma em
      condições razoáveis, o BIZPIK poderá interromper o fornecimento
      destas características da plataforma, sem conceder ao cliente
      qualquer reembolso, crédito ou compensação.
      <br />
      <br />
    </Typography>
  );
}

export default MiscellaneousSuppliers;
