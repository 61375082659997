import React from "react";
import { Redirect, useLocation } from "react-router-dom";

export default function InitializationFail() {
  const { state } = useLocation<{ message: string }>();

  if (!state || !state.message) {
    return <Redirect to={{ pathname: "/" }} exact />;
  }

  return (
    <main className="w-screen h-screen flex flex-col items-center justify-center">
      <img src="/assets/bizpik-icon-logo.svg" alt="bizpik logo" />
      <h1 style={{ fontSize: 24 }} className="mt-8 mb-2">
        Erro ao carregar a página
      </h1>
      <p style={{ fontSize: 18, fontWeight: 700 }}>{state.message}</p>
    </main>
  );
}
