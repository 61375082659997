/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import NotFound from "../pages/NotFound";
import routes from "./routes";

export default function Routes() {
  return (
    <BrowserRouter forceRefresh>
      <Switch>
        {routes.map((routeProps, index) => (
          <Route key={index} {...routeProps} />
        ))}
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}
