/* eslint-disable prettier/prettier */
import { Typography } from "@mui/material";

function Definitions() {
    return (
        <Typography>
            <strong>1. Definições</strong>
            <br />
            Definições. Os termos iniciados em letra maiúscula indicados a seguir
            serão utilizados neste instrumento com o significado detalhado abaixo:
            <br />
            <br />

            - “Afiliada” significa toda entidade que controla, é controlada por
            ou encontra-se sob controle comum de uma das entidades CLIENTES.
            “Controle” para todos os fins, significa a propriedade direta ou
            indireta ou controle de mais de 50% do capital com direito a voto.
            <br />
            <br />
            - “CLIENTE” significa o CLIENTE acima descrito ou qualquer de suas Afiliadas.
            <br />
            <br />
            - “Dados do CLIENTE” significam todos os dados eletrônicos ou informações submetidas pelo CLIENTE.
            <br />
            <br />
            - “Código Malicioso” significa vírus, vírus classificados como
            “worms”, bomba relógio, Cavalo de Tróia e outros códigos,
            arquivos, scripts, agentes ou programas, que sejam prejudiciais ou
            mal-intencionados.
            <br />
            <br />
            - “Aplicativos Não Fornecidos pelo BIZPIK” significa aplicativos
            online e softwares off-line fornecidos por entidades ou indivíduos
            que não o BIZPIK.
            <br />
            <br />
            - “Plataforma”, “Saas” ou “Sistema” significam os produtos e a
            plataforma onde o CLIENTE se cadastrou, informando seus dados e
            cadastrando uma senha de acesso. *“Plataforma” exclui Aplicativos
            não fornecidos pelo BIZPIK.
            <br />
            <br />
            - “Usuários” significam os indivíduos autorizados pelo CLIENTE a
            utilizar a plataforma, cadastrados pelo administrador master do
            cliente. Usuários poderão ser, sem limitar-se, empregados,
            consultores, contratados e agentes do CLIENTE, bem como terceiros
            com quem o CLIENTE venha a contratar.
            <br />
            <br />
            - “Violação” significa a violação de segurança que leva à destruição
            acidental ou ilegal, perda, alteração, divulgação não autorizada
            ou acesso a Dados Pessoais e/ou Dados Pessoais Sensíveis
            transmitidos, armazenados ou tratados de outra forma.
            <br />
            <br />
        </Typography>
    );
}

export default Definitions;
