/* eslint-disable prettier/prettier */
import { Typography } from "@mui/material";

function GeneralProvisions() {
    return (
        <Typography>
            <strong>13. Disposições Gerais</strong>
            <br />

            <strong>13.1.</strong> Violação dos Termos de Uso ou da legislação vigente. No caso de
            descumprimento, pelo Cliente, de qualquer disposição deste Termos
            de Uso ou da legislação vigente, o BIZPIK poderá,
            independentemente de qualquer aviso, notificação ou de qualquer
            outra formalidade, interromper, suspender ou cancelar, de
            imediato, temporária ou definitivamente, a conta e o acesso do
            Cliente à Plataforma, sem prejuízo de quaisquer outros direitos
            assegurados o BIZPIK por lei, pelo Termos de Uso e/ou pela
            Política de Privacidade, nada podendo ser reclamado ou cobrado do
            BIZPIK a esse respeito.
            <br />
            <br />
            <span className="hidden" style={{ backgroundColor: "#ff0" }}> Atraso no pagamento. O atraso no pagamento de qualquer obrigação prevista neste Termos de Uso sujeitará os valores devidos e não pagos à incidência de juros no valor de 1% ao mês, multa de 2% e correção monetária positiva segundo IPCA/IBGE ou outro que o venha substituir, caso seja extinto, aplicado pro rata dies desde a data do inadimplemento até o efetivo pagamento dos
                <strong>13.2</strong>
                referidos valores.
                <br />
                <br />
            </span>
            <strong>13.2.</strong> Independência das partes. Este Termos de Uso não estabelece qualquer
            forma de associação, franquia, parceria, consórcio, joint-venture,
            mandato, vínculo empregatício, societário ou solidariedade, tampouco
            confere às partes autorização para praticar quaisquer atos,
            representar, contratar ou assumir obrigações em nome da outra parte.
            <br />
            <br />
            <strong>13.3.</strong> Cessão. O Cliente não poderá ceder ou transferir a terceiros, a
            qualquer título, no todo ou em parte, os direitos e obrigações
            decorrentes ou relacionados a estes Termos de Uso, salvo se com a expressa e prévia autorização por escrito do BIZPIK. O Cliente
            poderá ceder este Contrato em sua integralidade, em virtude de
            fusão, aquisição, reorganização societária ou venda de todos ou
            parte substancial de seus ativos, desde que não envolva concorrente
            direto do BIZPIK e desde que mediante o envio de documentação
            comprobatória ao BIZPIK e posterior aceite expresso do BIZPIK. O
            BIZPIK, por sua vez, está autorizado a ceder ou transferir a
            terceiros direitos e obrigações decorrentes ou relacionados a este
            Termos de Uso sem a necessidade de anuência prévia do Cliente. A
            medida cabível para a hipótese de violação por uma das partes da
            presente cláusula deverá ser a critério da parte inocente, a
            rescisão do presente contrato, mediante notificação prévia à parte
            infratora.
            <br />
            <br />
            <strong>13.4.</strong> Revisões ou atualizações. O BIZPIK poderá alterar, a qualquer tempo,
            este Termo de Uso. Fica estabelecido que as novas versões dos Termos
            de Uso entrarão em vigor na data de sua publicação, sendo que o
            Cliente somente poderá continuar a utilizar a Plataforma caso
            concorde ou não discorde expressamente com as referidas alterações.
            O Cliente poderá, caso não concorde com a referida nova versão,
            extinguir o seu cadastro da Plataforma. Nessa hipótese, o vínculo
            contratual deixará de existir, a critério exclusivo do Cliente. O
            uso da Plataforma, após ter sido comunicado a respeito das
            alterações aos Termos de Uso, será considerado como aceite, pelo
            Cliente, da nova versão dos Termos de Uso.
            <br />
            <br />
            <strong>13.5.</strong> Acordo integral. Este Termos de Uso supera, substitui e prevalece
            sobre qualquer acordo ou contrato prévio, escrito ou verbal, que
            tenha sido levado a efeito pelas partes com relação aos assuntos
            aqui contemplados.
            <br />
            <br />
            <strong>13.6.</strong> Sucessores. Este Termos de Uso vincula o Cliente e o BIZPIK ao seu
            fiel cumprimento, bem como seus sucessores a qualquer título, em
            caráter irrevogável e irretratável.
            <br />
            <br />

            <strong>13.7.</strong> Necessidade de renúncia expressa e escrita e princípio da
            conservação. O BIZPIK e os Clientes declaram e acordam expressamente
            que: (a) o não exercício ou o atraso no exercício de qualquer
            direito que lhes seja assegurado por este instrumento ou por lei não
            constituirá novação ou renúncia a tal direito, nem prejudicará o
            eventual exercício do mesmo; (b) a renúncia a qualquer desses
            direitos somente será válida se formalizada por escrito (incluindo
            eletronicamente), se e quando tal renúncia não for vedada por lei; e
            (c) a nulidade ou invalidade de qualquer das cláusulas deste
            instrumento não prejudicará a validade e eficácia das demais
            cláusulas. Caso seja decretada a nulidade, anulabilidade ou
            ineficácia de qualquer das cláusulas deste instrumento.
            <br />
            <br />
            <strong>13.8.</strong> Títulos. Os títulos das cláusulas ou capítulos deste Termo de Uso
            são meramente ilustrativos, e foram inseridos para facilitar a
            compreensão do Usuário.
            <br />
            <br />
            <strong>13.9.</strong> Tributos. Exceto se previsto expressamente de forma diversa, os
            tributos devidos direta ou indiretamente em decorrência da execução
            deste Termo de Uso e do uso da Plataforma, existentes e/ou que
            venham a ser criados, bem como as respectivas majorações, mudanças
            de base de cálculo e/ou do período de apuração, reajustes, encargos
            moratórios e/ou obrigações tributárias acessórias, constituem ônus
            de responsabilidade do respectivo sujeito passivo da obrigação
            tributária, conforme definido na legislação vigente.
            <br />
            <br />
            <strong>13.10.</strong> Anticorrupção. O CLIENTE declara que não lhe foi dado ou oferecido
            qualquer propina, suborno, pagamento, presente ou bens de valor por
            empregados do BIZPIK, ou agentes relacionados a este Contrato. Caso
            o CLIENTE tome conhecimento de qualquer violação à presente
            restrição, deverá utilizar esforços razoáveis para imediatamente notificar o Departamento Legal do BIZPIK.

            <br />
            <br />
            <strong>13.11.</strong> Foro. Em caso de disputas ou desentendimentos a respeito deste
            Termos de Uso, fica eleito o Foro da Comarca de São Paulo, Estado de
            São Paulo, para dirimir quaisquer dúvidas ou controvérsias, com a
            exclusão de qualquer outro, por mais privilegiado que seja ou venha
            a ser.
            <br />
            <br />
        </Typography>
    );
}

export default GeneralProvisions;
