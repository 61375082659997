/* eslint-disable prettier/prettier */
import { Typography } from "@mui/material";

function TermAndTermination() {
  return (
    <Typography>

      <strong>8. Vigência e término</strong>
      <br />
      <strong>8.1.</strong> Prazo de vigência. Este Termos de Uso entra em vigor na data de
      sua aceitação pelo Cliente e permanece em vigor pelo tempo de
      contratação ou até que seja substituído por novo documento padrão,
      que passará a viger com o próximo pagamento do CLIENTE.
      <br />
      <br />
      <strong>8.2.</strong> Havendo interesse na rescisão, o CLIENTE deverá solicitar o
      cancelamento da sua assinatura ao BIZPIK com 30 dias de
      antecedência, continuará a ter acesso ao serviço BIZPIK até o fim
      do período de faturamento. Na extensão permitida pelas leis
      vigentes, os pagamentos não são reembolsáveis e o BIZPIK não dará
      créditos por períodos de contratação não utilizados.
      <br />
      <br />

      <span className="hidden" style={{ backgroundColor: "#ff0" }}>Para solicitar o cancelamento o CLIENTE deve acessar a tela
        de configurações do seu BIZPIK, clicar na opção “Solicitar Cancelamento” ou “Cancelar Assinatura” e seguir as instruções
        de cancelamento. Se o CLIENTE cancelar sua assinatura, sua
        conta será automaticamente encerrada ao fim do período de
        cobrança em andamento.

        <br />
        <br />
      </span>
      <strong>8.3.</strong> O preço unitário poderá ser atualizado de acordo com a taxa de
      correção aplicada na tabela de preços se disponível, na próxima
      renovação programada.
      <br />
      <br />
      <strong>8.4.</strong> Sobrevivência. Em qualquer hipótese de término deste Termos de
      Uso, continuarão a ser aplicáveis as obrigações de proteção de
      propriedade intelectual do BIZPIK, indenidade e limitação de
      responsabilidade do BIZPIK, sigilo e obrigação de indenizar
      eventuais perdas e danos decorrentes do descumprimento deste
      instrumento ou da legislação aplicável, que sobreviverão pelos
      prazos prescricionais e/ou decadenciais aplicáveis, ou caso ocorra
      por último, até que a pretensão em questão seja satisfeita.
      <br />
      <br />
    </Typography>

  );
}

export default TermAndTermination;
