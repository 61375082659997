/* eslint-disable no-param-reassign */
import i18n from "../i18n";
import { IApiResponseTranslation, IPageConfigResponseModel } from "../types/api";
import { webSiteInfo } from "./data";

export function addPageConfigI18nTranslations(translation: IApiResponseTranslation) {
  i18n.addResourceBundle("pt", "common", translation.ptBR, true, true);
  i18n.addResourceBundle("en", "common", translation.enUS, true, true);
  i18n.addResourceBundle("es", "common", translation.esES, true, true);
}

export function overridePageConfig(pageConfig: IPageConfigResponseModel, partnerId: number) {
  const partnerConfig = pageConfig.partner;

  if (!partnerConfig || partnerId === webSiteInfo.bizpik.id) {
    return pageConfig;
  }

  // handling eduzz partner configuration
  if (partnerId === webSiteInfo.eduzz.id && partnerConfig.eduzz) {
    const eduzzPartnerConfig = partnerConfig.eduzz;

    pageConfig.client = eduzzPartnerConfig.section.client;
    pageConfig.comments = eduzzPartnerConfig.section.comments;
    pageConfig.plans = eduzzPartnerConfig.section.plans;
    pageConfig.partner = { eduzz: eduzzPartnerConfig };
  }
  // handling alpop partner configuration
  else if (partnerId === webSiteInfo.alpop.id && partnerConfig.alpop) {
    const alpopPartnerConfig = partnerConfig.alpop;

    pageConfig.client = alpopPartnerConfig.section.client;
    pageConfig.comments = alpopPartnerConfig.section.comments;
    pageConfig.plans = alpopPartnerConfig.section.plans;
    pageConfig.partner = { alpop: alpopPartnerConfig };
  } else {
    throw Error(`Invalid partner ID '${partnerId}'.`);
  }

  return pageConfig;
}
