import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import GridContainer from "../../components/GridContainer";
import NavigationTemplate from "../../templates/NavigationTemplate";
import { createNavigationLinks } from "../../payload/navigation";
import { webSiteInfo } from "../../payload/data";
import TermsFragment from "./terms-fragments";

export default function Policy() {
  return (
    <NavigationTemplate
      links={createNavigationLinks(webSiteInfo.bizpik.path, {
        clientsLinkVisible: false,
        faqLinkVisible: true,
        homepageLinkVisible: true,
        purposeLinkVisible: true,
        solutionsLinkVisible: true,
        subsLinkVisible: true
      })}
    >
      <Box component="section">
        <GridContainer>
          <Grid item xs={12} sm={10}>
            <Typography className="font-bold mb-8" fontSize={32}>
              Termos e condições de uso da Plataforma Bizpik
            </Typography>

            <TermsFragment />
          </Grid>
        </GridContainer>
      </Box>
    </NavigationTemplate>
  );
}
