import React from "react";
import { Box, Grid, Typography } from "@mui/material";

import GridContainer from "../../components/GridContainer";
import Translate from "../../components/Translate";
import NavigationTemplate from "../../templates/NavigationTemplate";
import { createNavigationLinks } from "../../payload/navigation";
import { webSiteInfo } from "../../payload/data";

export default function Policy() {
  return (
    <NavigationTemplate
      links={createNavigationLinks(webSiteInfo.bizpik.path, {
        clientsLinkVisible: false,
        faqLinkVisible: true,
        homepageLinkVisible: true,
        purposeLinkVisible: true,
        solutionsLinkVisible: true,
        subsLinkVisible: true
      })}
    >
      <Box component="section">
        <GridContainer>
          <Grid item xs={12} sm={10}>
            <Typography
              className="font-bold mb-8"
              fontSize={32}
              children={<Translate i18nKey="nav:policy" />}
            />

            <Typography className="flex flex-col justify-between text-justify">
              <Translate
                i18nKey="misc:policy"
                components={{
                  "1": <strong />,
                  p: <p />,
                  br: <br />
                }}
              />
            </Typography>
          </Grid>
        </GridContainer>
      </Box>
    </NavigationTemplate>
  );
}
