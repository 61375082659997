export default {
  guppieGreen: "#1AFF80",
  guppieGreenDarker: "#1AE076",
  darkGunmetal: "#1A1D22",
  violet: "#801AFF",
  violetDarker: "#8014CC",
  dodgerBlue: "#1A99FF",
  dodgeBlueDarker: "#1987e0",
  deepPink: "#FF1A99",
  deepPinkDarker: "#DF1188",
  vividTangerine: "#FF801A",
  yellowSunny: "#F6EB78"
};
