import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { FiCheckSquare } from "react-icons/fi";
import clsx from "clsx";

interface CheckBoxListItemProps {
  label: React.ReactNode | string;
  visible?: boolean;
}

export default function CheckBoxListItem({ label, visible = true }: CheckBoxListItemProps) {
  return (
    <ListItem className={clsx(!visible && "opacity-0")} disablePadding>
      <ListItemIcon>
        <FiCheckSquare />
      </ListItemIcon>

      <ListItemText primary={label} classes={{ primary: "truncate block" }} />
    </ListItem>
  );
}
