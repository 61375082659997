import { createTheme } from "@mui/material";

const lightTheme = createTheme({
  palette: {
    background: {
      default: "#F6F7F8"
    },

    grey: {
      "100": "#F6F7F8",
      "200": "#EFF1F4",
      "300": "#DFE3E7",
      "400": "#7C8593",
      "500": "#54575D",
      "600": "#3A3E43",
      "700": "#292D34",
      "800": "#22262A",
      "900": "#1A1D22"
    },

    text: {
      primary: "#22262A",
      secondary: "#7C8593"
    }
  },

  breakpoints: {
    values: {
      // extra-small
      xs: 0,
      // small
      sm: 600,
      // medium
      md: 900,
      // large
      lg: 1200,
      // extra-large
      xl: 1440
    }
  }
});

export default lightTheme;
