/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { i18n as I18n, Namespace } from "i18next";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { ITranslationDictionary, ITranslationLanguage } from "../types/i18n";
import languages from "../i18n/languages";
import { I18N_COMMON_NS } from "../i18n/namespace";

type StateTranslationLangs = ITranslationLanguage & { id: keyof ITranslationDictionary };

interface AppTranslationsState<TF> {
  t: TF;
  ns: Namespace;
  i18n: I18n;
  langs: StateTranslationLangs[];
  lang: {
    current: keyof ITranslationDictionary;
    tsrc: string;
    iconUrl: string;
    change: (lang: keyof ITranslationDictionary) => Promise<TF>;
  };
}

export default function useAppTranslations() {
  const [t, i18n] = useTranslation("common");

  // fixed the issue that makes t function always require a key of
  // the resource namespace instead of the TemplateStringsArray type
  const fixedT = t as typeof t & ((key: string | string[]) => string);

  const changeLanguage = (lang: keyof ITranslationDictionary) =>
    i18n.changeLanguage(lang) as Promise<typeof fixedT>;

  // gets user browser default language through LanguageDetector plugin
  // so the value retrieved is the most accurate language to be shown at
  // first sight
  const i18nLang = (i18n.resolvedLanguage || i18n.language) as keyof ITranslationDictionary;

  const translationLang = languages[i18nLang];

  const lang = {
    current: i18nLang,
    tsrc: translationLang.tsrc,
    iconUrl: translationLang.iconUrl,
    change: changeLanguage
  };

  const langs = _.map(languages, (x, y) => ({
    id: y as keyof ITranslationDictionary,
    ...x
  }));

  return {
    i18n,
    lang,
    t: fixedT,
    ns: I18N_COMMON_NS,
    langs
  } as AppTranslationsState<typeof fixedT>;
}
