/* eslint-disable react/jsx-props-no-spreading */
import _ from "lodash";
import { Avatar, Card, CardContent, CardHeader, CardProps, Typography } from "@mui/material";
import { BiCheckDouble } from "react-icons/bi";
import { format } from "date-fns";
import colors from "../themes/colors";
import ChatBubbleContainer from "./ChatBubbleContainer";
import { mergeMUISxProps } from "../utils/material";
import { webSiteInfo } from "../payload/data";

interface UserCommentCardProps {
  userImageSrc: string;
  userName: string;
  commentText: string | React.ReactNode;
  commentDateTime: number | Date;
  commentMaxLines?: 1 | 2 | 3 | 4 | 5 | 6;
  commentOrigin?: string;
  showDate?: boolean;
  childrenProps?: Partial<{ muiCard: CardProps }>;
}

export default function UserCommentCard({
  showDate,
  userImageSrc,
  userName,
  commentOrigin = webSiteInfo.bizpik.name,
  commentDateTime,
  commentMaxLines = 3,
  commentText,
  childrenProps
}: UserCommentCardProps) {
  const dateTime = new Date(commentDateTime);

  return (
    <Card
      sx={mergeMUISxProps(
        theme => ({
          position: "relative",
          minWidth: "20rem",
          borderRadius: 0,
          boxShadow: theme.shadows[3],

          [theme.breakpoints.up("sm")]: {
            minWidth: "26rem"
          },

          [theme.breakpoints.up("xl")]: {
            minWidth: "auto",
            width: "100%"
          }
        }),
        childrenProps?.muiCard?.sx
      )}
      {...(_.omit(childrenProps?.muiCard, "sx") ?? {})}
    >
      <CardHeader
        classes={{ avatar: "mr-3", action: "m-0 self-center", root: "pb-2" }}
        avatar={
          <Avatar sx={{ width: 48, height: 48 }} src={userImageSrc} alt="user profile image" />
        }
        title={
          <Typography fontSize={18} className="font-bold" component="h6" variant="inherit">
            {userName}
            <Typography
              color={theme => theme.palette.text.secondary}
              component="span"
              variant="inherit"
              children=" → "
            />
            <Typography
              fontSize={16}
              fontWeight={500}
              color={theme => theme.palette.text.secondary}
              component="span"
              children={commentOrigin}
            />
          </Typography>
        }
        action={
          !showDate ? undefined : (
            <Typography
              fontSize={16}
              className="font-bold"
              component="h6"
              children={format(dateTime, "dd/MM/yyyy")}
              color={colors.dodgerBlue}
            />
          )
        }
      />

      <CardContent className="p-4 pt-0">
        <ChatBubbleContainer>
          <Typography
            className={`mb-2 font-normal line-clamp-${commentMaxLines}`}
            fontSize={16}
            variant="inherit"
          >
            {commentText}
          </Typography>

          <Typography className="font-medium text-right" fontSize={12}>
            {format(dateTime, showDate ? "HH:mm" : "dd/MM/yyyy - HH:mm")}&nbsp;
            <BiCheckDouble
              fontSize={18}
              className="inline align-top"
              color={colors.dodgerBlue}
            />
          </Typography>
        </ChatBubbleContainer>
      </CardContent>
    </Card>
  );
}
