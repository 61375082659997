import React from "react";
import { Box, Grid, Hidden, Stack, Typography } from "@mui/material";
import ChannelSlider from "../../../components/ChannelSlider";
import CheckBoxList from "../../../components/CheckBoxList";
import CheckBoxListItem from "../../../components/CheckBoxListItem";
import GridContainer from "../../../components/GridContainer";
import Translate from "../../../components/Translate";
import colors from "../../../themes/colors";
import UserCommentCard from "../../../components/UserCommentCard";

import { IPageConfigComment, IPageConfigOrigin } from "../../../types/page";
import PageContentAnchor from "../../../templates/NavigationTemplate/components/PageContentAnchor";

interface WelcomeSectionProps {
  origins: IPageConfigOrigin[];
  comments: IPageConfigComment[];
}

export default function WelcomeSection({ origins, comments }: WelcomeSectionProps) {
  return (
    <Box component="section">
      <GridContainer>
        <PageContentAnchor id="homepage" />

        <Grid container justifyContent="center">
          <Hidden mdDown>
            <Grid item xs={1} />
          </Hidden>

          <Grid item xs={12} sm={10} md={5}>
            <Box>
              <Typography
                variant="subtitle1"
                className="font-bold mb-8"
                children={<Translate i18nKey="section:start:subtitle" />}
                sx={{ fontSize: 24 }}
              />

              <Typography
                component="h3"
                className="max-w-[15em]"
                color={colors.dodgerBlue}
                sx={{
                  lineHeight: 1.2,
                  fontWeight: 700,
                  fontSize: 32
                }}
              >
                <Translate
                  i18nKey="section:start:title"
                  components={{
                    "1": (
                      <Typography
                        variant="h3"
                        component="span"
                        sx={{
                          fontWeight: 700,
                          fontSize: 32,
                          display: "inline",
                          lineHeight: 0.8,
                          color: "inherit"
                        }}
                      />
                    ),
                    "2": (
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: 700,
                          fontSize: 32,
                          display: "inline",
                          lineHeight: 0.8,
                          borderBottom: `solid 4px ${colors.deepPink}`,
                          color: colors.deepPink
                        }}
                      />
                    ),
                    "3": (
                      <Typography
                        component="span"
                        sx={{
                          fontWeight: 700,
                          fontSize: 32,
                          display: "inline",
                          lineHeight: 0.8,
                          borderBottom: "none",
                          color: colors.deepPink
                        }}
                      />
                    )
                  }}
                />
              </Typography>
            </Box>

            <Box className="overflow-hidden mt-8">
              <ChannelSlider origins={origins} />
            </Box>

            <Hidden smUp>
              <Box className="relative mt-32 -mx-4">
                <Box className="absolute -left-4 -top-20">
                  <img className="w-[200px]" src="/assets/pik-icon.svg" alt="pik" />
                </Box>

                <Box className="absolute" sx={{ bottom: "-7.5rem", right: "-10rem" }}>
                  <img className="w-[280px]" src="/assets/biz-icon.svg" alt="biz" />
                </Box>

                <Box className="overflow-x-auto pb-6">
                  <Stack direction="row" spacing={3}>
                    {comments.map(comment => (
                      <UserCommentCard
                        key={comment.personName}
                        childrenProps={{
                          muiCard: { className: "min-w-full" }
                        }}
                        userImageSrc={comment.personImage}
                        userName={comment.personName}
                        commentText={<Translate customKey={comment.text} />}
                        commentMaxLines={5}
                        commentDateTime={comment.creationDate}
                      />
                    ))}
                  </Stack>
                </Box>
              </Box>
            </Hidden>

            <Box className="flex mt-12 relative z-10 sm:static sm:z-0 sm:mt-24 md:mt-36">
              <Box>
                <Typography
                  variant="button"
                  color={theme => theme.palette.text.secondary}
                  children={<Translate i18nKey="section:start:advantage:subtitle" />}
                />

                <Typography
                  color={colors.dodgerBlue}
                  sx={{ fontWeight: 700, fontSize: 40 }}
                  children={<Translate i18nKey="section:start:advantage:title" />}
                />

                <CheckBoxList checkBoxcolor={colors.dodgerBlue}>
                  <CheckBoxListItem
                    label={<Translate i18nKey="section:start:advantage:check_1" />}
                  />
                  <CheckBoxListItem
                    label={<Translate i18nKey="section:start:advantage:check_2" />}
                  />
                  <CheckBoxListItem
                    label={<Translate i18nKey="section:start:advantage:check_3" />}
                  />
                  <CheckBoxListItem
                    label={<Translate i18nKey="section:start:advantage:check_4" />}
                  />
                  <CheckBoxListItem
                    label={<Translate i18nKey="section:start:advantage:check_5" />}
                  />
                </CheckBoxList>
              </Box>

              <Hidden mdUp smDown>
                <Box className="relative w-1/2 left-1/2 -translate-x-1/2">
                  <img
                    className="absolute bottom-0 -left-8"
                    src="/assets/phone-view.png"
                    alt="phone-view"
                  />
                </Box>
              </Hidden>
            </Box>
          </Grid>

          <Hidden smDown>
            <Grid
              item
              xs={10}
              md={5}
              className="mt-12 relative justify-center md:justify-start md:static"
            >
              <Box className="absolute top-0">
                <img src="/assets/pik-icon.svg" width="200" alt="pik" />
              </Box>

              <Box className="w-full flex flex-col items-center relative z-10">
                {comments.map((comment, index) => (
                  <UserCommentCard
                    key={comment.personName}
                    childrenProps={{
                      muiCard: {
                        className:
                          (index === 0 && "ml-32 z-0") ||
                          (index === 1 && "-mt-8 mr-32 z-10") ||
                          (index === 2 && "-mt-10 ml-32 z-20") ||
                          "hidden"
                      }
                    }}
                    userImageSrc={comment.personImage}
                    userName={comment.personName}
                    commentText={<Translate customKey={comment.text} />}
                    commentMaxLines={5}
                    commentDateTime={comment.creationDate}
                    showDate
                  />
                ))}
              </Box>

              <Hidden mdUp>
                <Box className="absolute -right-12" sx={{ bottom: "-6rem" }}>
                  <img className="w-[420px]" src="/assets/biz-icon.svg" alt="biz" />
                </Box>
              </Hidden>
            </Grid>
          </Hidden>

          <Hidden mdDown>
            <Grid item xs={1}>
              <Box className="absolute right-0" sx={{ bottom: "-3rem" }}>
                <img className="w-[50%] md:w-[420px]" src="/assets/biz-icon.svg" alt="biz" />
              </Box>
            </Grid>
          </Hidden>
        </Grid>

        <Hidden mdDown>
          <Grid className="justify-center relative h-0" container>
            <Grid
              item
              xs={4}
              className="absolute z-30 top-0"
              sx={{
                transform: "translateY(calc(8rem - 100%)) translateX(4rem)"
              }}
            >
              <img src="/assets/phone-view.png" alt="phone-view" />
            </Grid>
          </Grid>
        </Hidden>
      </GridContainer>
    </Box>
  );
}
