/* eslint-disable prettier/prettier */
import { Typography } from "@mui/material";

function AdministrationAndDeclaration() {
    return (
        <Typography>
            <strong>11. Administração do Serviço e da Declaração de Responsabilidade</strong>
            <br />
            <strong>11.1</strong> A senha que possibilita o acesso para o conteúdo e para a
            administração (programação e alterações) do serviço contratado
            será escolhida e cadastrada pelo CLIENTE, sem qualquer
            participação do BIZPIK, sendo de exclusiva responsabilidade do
            CLIENTE a definição da política de segurança.
            <br />
            <br />
            <strong>11.1.1</strong> Apenas o endereço eletrônico de “e-mail” cadastrado pelo
            CLIENTE receberá a senha de administração e suas eventuais
            substituições e alterações.
            <br />
            <br />
            <strong> 11.1.2</strong> A posse da senha dará a quem a detiver não só amplos poderes
            de administração do serviço, mas também amplos poderes de
            alterar eletronicamente a própria senha.
            <br />
            <br />
            <strong>11.1.4</strong> A responsabilidade por permitir o acesso à senha a quem
            quer que seja, corre por conta única e exclusiva da
            CLIENTE uma vez que o BIZPIK não possui qualquer
            ingerência sobre a disponibilização da utilização da senha
            inicialmente fornecida.
            <br />
            <br />

            <strong>11.2</strong> Em caso de pedido de substituição do endereço eletrônico de
            e-mail para envio de senha, o BIZPIK apenas o atenderá mediante
            a apresentação, pelo solicitante, dos documentos que comprovem
            sua legitimidade para efetivação da solicitação. Após a
            apresentação de documentos hábeis, o BIZPIK enviará a nova senha
            para o endereço eletrônico de “e-mail” indicado na solicitação
            de substituição.
            <br />
            <br />
            <strong>11.2.1</strong> Em caso de disputa pela posse da senha de administração do
            serviço o acesso à mesma ficará bloqueado até que os
            interessados cheguem a um acordo escrito e deem conhecimento
            do mesmo o BIZPIK.

            <br />
            <br />
            <strong>11.2.2</strong> Caracteriza-se disputa pela posse da senha de administração
            justificadora de bloqueio da mesma o envio de mais de duas
            solicitações de substituição efetuados por pessoas
            legitimadas, além de qualquer outra que seja manifestada
            expressamente por qualquer das pessoas legitimadas a pedir
            essa substituição.
            <br />
            <br />

        </Typography>
    );
}

export default AdministrationAndDeclaration;
