/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Box,
  BoxProps,
  Button,
  Grid,
  GridProps,
  Hidden,
  Stack,
  Typography
} from "@mui/material";
import GridContainer from "./GridContainer";
import PageContentAnchor from "../templates/NavigationTemplate/components/PageContentAnchor";
import Translate from "./Translate";
import UserCommentCard from "./UserCommentCard";
import { IPageConfigComment, IPageConfigOrigin } from "../types/page";
import { IPageConfigPartnerColors } from "../types/partner/basic";
import ChannelSlider from "./ChannelSlider";

interface PartnerLandscapeProps {
  comments: IPageConfigComment[];
  colors: IPageConfigPartnerColors;
  partnerLogoUrl: string;
  partnerBannerUrl: string;
  partnerName: string;
  childrenProps?: Partial<{ bannerGrid: GridProps; bannerImg: BoxProps }>;
  origins?: IPageConfigOrigin[];
}

export default function PartnerLandscape({
  comments,
  colors,
  partnerName,
  partnerLogoUrl,
  partnerBannerUrl,
  childrenProps,
  origins
}: PartnerLandscapeProps) {
  return (
    <Box sx={{ backgroundColor: `${colors.banner} !important` }} component="section">
      <GridContainer
        childrenProps={{
          muiGrid: { minHeight: "560px", paddingBottom: "64px !important" }
        }}
      >
        <PageContentAnchor id="homepage" />

        <Grid xs={12} sm={10} container className="justify-center md:justify-start">
          <Grid item xs={12} md={8} lg={5}>
            <Stack
              display="inline-flex"
              direction={["column", "row", "row", "row", "row"]}
              gap={2}
              marginBottom={2}
              alignItems={["left", "center"]}
            >
              <Box>
                <Button
                  href="#purpose"
                  className="py-1.5 h-auto rounded-full shadow-none block"
                  sx={{
                    color: colors.buttonText,
                    backgroundColor: colors.buttonBackground,
                    textTransform: "none",
                    fontSize: 14,
                    lineHeight: "22px",
                    ":hover": {
                      backgroundColor: colors.buttonBackground
                    }
                  }}
                  variant="contained"
                  children={
                    <Translate customKey="section:partner:label:clients_and_partners" />
                  }
                />
              </Box>

              <img src={partnerLogoUrl} alt={partnerName} width="120" />
            </Stack>

            <Typography
              variant="subtitle1"
              className="font-bold mb-8"
              lineHeight="28px"
              color={colors.textPrimary}
              children={
                <Translate
                  customKey="section:partner:landscape:subheader"
                  values={{ client: partnerName }}
                />
              }
              sx={{ fontSize: 24 }}
            />

            <Typography
              component="h3"
              className="max-w-[15em]"
              color={colors.textContrast}
              sx={{
                lineHeight: 1.2,
                fontWeight: 700,
                fontSize: 32
              }}
            >
              <Translate
                customKey="section:partner:landscape:title"
                components={{
                  "1": (
                    <Typography
                      variant="h3"
                      component="span"
                      sx={{
                        fontWeight: 700,
                        fontSize: 32,
                        display: "inline",
                        lineHeight: 0.8,
                        color: "inherit"
                      }}
                    />
                  ),
                  "2": (
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 700,
                        fontSize: 32,
                        display: "inline",
                        lineHeight: 0.8,
                        borderBottom: `solid 4px ${colors.textPrimary}`,
                        color: colors.textPrimary
                      }}
                    />
                  ),
                  "3": (
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: 700,
                        fontSize: 32,
                        display: "inline",
                        lineHeight: 0.8,
                        borderBottom: "none",
                        color: colors.textPrimary
                      }}
                    />
                  )
                }}
              />
            </Typography>

            <Box className="overflow-hidden mt-8">
              <ChannelSlider origins={origins ?? []} />
            </Box>
          </Grid>

          <Hidden lgDown>
            <Grid className="relative" item md={7} {...childrenProps?.bannerGrid}>
              <Box
                component="img"
                src={partnerBannerUrl}
                alt="banner"
                {...childrenProps?.bannerImg}
              />
            </Grid>
          </Hidden>

          <Grid
            className="absolute top-[100%] -mt-16 right-0 lg:right-auto z-50"
            item
            xs={12}
            sm={11}
            lg={10}
          >
            <Stack
              className="overflow-x-auto pb-8 px-8 sm:pl-0 lg:px-0"
              direction="row"
              spacing={3}
            >
              {comments.map(comment => (
                <UserCommentCard
                  key={comment.personName}
                  userImageSrc={comment.personImage}
                  userName={comment.personName}
                  commentText={<Translate customKey={comment.text} />}
                  commentMaxLines={5}
                  commentDateTime={comment.creationDate}
                  commentOrigin={comment.origin}
                />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </GridContainer>
    </Box>
  );
}
