import React from "react";
import { Box, Grid, Paper, styled, Typography } from "@mui/material";

import AccordionDescription from "../../../components/AccordionDescription";
import GridContainer from "../../../components/GridContainer";
import Translate from "../../../components/Translate";
import colors from "../../../themes/colors";

const AccordionContainerFAQSection = styled(Paper)(({ theme }) => ({
  borderRadius: 0,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  boxShadow: theme.shadows[3],
  marginLeft: theme.spacing(-4),
  marginRight: theme.spacing(-4),

  [theme.breakpoints.up("sm")]: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    marginLeft: 0,
    marginRight: 0
  },

  [`
    & .MuiAccordionSummary-content:not(.Mui-expanded),
    & .MuiAccordionSummary-content.Mui-expanded
  `]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}));

export default function FAQSection() {
  return (
    <Box component="section">
      <GridContainer>
        <div className="absolute -top-16 md:-top-40 h-0 w-0" id="faq" />

        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10}>
            <Typography
              variant="button"
              color={theme => theme.palette.text.secondary}
              children={<Translate i18nKey="section:faq:subtitle" />}
            />

            <Typography
              color={colors.dodgerBlue}
              sx={{ fontWeight: 700, fontSize: 40 }}
              children={<Translate i18nKey="section:faq:title" />}
              className="mb-12"
            />
          </Grid>

          <Grid item xs={12} sm={10} justifyContent="center">
            <AccordionContainerFAQSection>
              <AccordionDescription
                id="faq-accordion-1"
                title={<Translate i18nKey="section:faq:accordion_1:title" />}
                text={<Translate i18nKey="section:faq:accordion_1:text" />}
                size="small"
              />

              <AccordionDescription
                id="faq-accordion-2"
                title={<Translate i18nKey="section:faq:accordion_2:title" />}
                text={<Translate i18nKey="section:faq:accordion_2:text" />}
                size="small"
              />

              <AccordionDescription
                id="faq-accordion-3"
                title={<Translate i18nKey="section:faq:accordion_3:title" />}
                text={<Translate i18nKey="section:faq:accordion_3:text" />}
                size="small"
              />

              <AccordionDescription
                id="faq-accordion-4"
                title={<Translate i18nKey="section:faq:accordion_4:title" />}
                text={<Translate i18nKey="section:faq:accordion_4:text" />}
                size="small"
              />

              <AccordionDescription
                id="faq-accordion-5"
                title={<Translate i18nKey="section:faq:accordion_5:title" />}
                text={<Translate i18nKey="section:faq:accordion_5:text" />}
                size="small"
              />

              {/* <AccordionDescription
                id="faq-accordion-6"
                title={<Translate i18nKey="section:faq:accordion_6:title" />}
                text={<Translate i18nKey="section:faq:accordion_6:text" />}
                size="small"
              />

              <AccordionDescription
                id="faq-accordion-7"
                title={<Translate i18nKey="section:faq:accordion_7:title" />}
                text={<Translate i18nKey="section:faq:accordion_7:text" />}
                size="small"
              />

              <AccordionDescription
                id="faq-accordion-8"
                title={<Translate i18nKey="section:faq:accordion_8:title" />}
                text={<Translate i18nKey="section:faq:accordion_8:text" />}
                size="small"
              />

              <AccordionDescription
                id="faq-accordion-9"
                title={<Translate i18nKey="section:faq:accordion_9:title" />}
                text={<Translate i18nKey="section:faq:accordion_9:text" />}
                size="small"
              />

              <AccordionDescription
                id="faq-accordion-10"
                title={<Translate i18nKey="section:faq:accordion_10:title" />}
                text={<Translate i18nKey="section:faq:accordion_10:text" />}
                size="small"
              /> */}
            </AccordionContainerFAQSection>
          </Grid>
        </Grid>
      </GridContainer>
    </Box>
  );
}
