import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";

import GridContainer from "../../../components/GridContainer";
import PricingCard from "../../../components/PricingCard";
import Translate from "../../../components/Translate";
import colors from "../../../themes/colors";
import { IPageConfigPlan } from "../../../types/page";

interface SubscriptionSectionProps {
  plans: IPageConfigPlan[];
}

export default function SubscriptionSection({ plans }: SubscriptionSectionProps) {
  const largestFeatureListLength = Math.max(...plans.map(x => x.information.features.length));

  return (
    <Box component="section">
      <GridContainer>
        <div className="absolute -top-16 md:-top-40 h-0 w-0" id="subs" />

        <Grid item xs={12} sm={10} justifyContent="center">
          <Box>
            <Typography
              variant="button"
              color={theme => theme.palette.text.secondary}
              children={<Translate i18nKey="section:subs:subtitle" />}
            />

            <Typography
              color={colors.dodgerBlue}
              sx={{ fontWeight: 700, fontSize: 40 }}
              children={<Translate i18nKey="section:subs:title" />}
              className="mb-12"
            />
          </Box>

          <Box className="overflow-x-scroll pb-6">
            <Stack direction="row" spacing={2}>
              {plans
                .map<IPageConfigPlan>(plan => {
                  // modifying plans to keep features array with the same length
                  const fixedLengthFeatures = plan.information.features
                    .concat(new Array(largestFeatureListLength).fill(""))
                    .slice(0, largestFeatureListLength);

                  return {
                    ...plan,
                    information: {
                      ...plan.information,
                      features: fixedLengthFeatures
                    }
                  };
                })
                .map(plan => (
                  <PricingCard
                    key={plan.id}
                    title={plan.name}
                    text={<Translate customKey={plan.information.description} />}
                    lightColorTheme={plan.information.color}
                    monthlyPrice={plan.price}
                    features={plan.information.features}
                    obs={{
                      service: <Translate customKey={plan.information.observations[0]} />,
                      storage: <Translate customKey={plan.information.observations[1]} />
                    }}
                  />
                ))}
            </Stack>
          </Box>
        </Grid>
      </GridContainer>
    </Box>
  );
}
