/* eslint-disable prettier/prettier */
import { Typography } from "@mui/material";

function IntellectualProperty() {
    return (
        <Typography>
            <strong>5. Propriedade Intelectual</strong>
            <br />
            <strong> 5.1.</strong> Ausência de transferência de direitos de propriedade intelectual.
            O Cliente reconhece e aceita que este Termos de Uso e a utilização
            da Plataforma não lhe conferem nenhum direito, incluindo, mas não
            se limitando a direitos autorais e/ou direitos de propriedade
            industrial sobre a Plataforma e/ou qualquer funcionalidade ou
            serviço disponibilizado por meio da Plataforma, bem como sobre
            todos e quaisquer materiais e conteúdos de qualquer natureza que
            digam respeito e/ou estejam relacionados à Plataforma ou o BIZPIK
            seus parceiros comerciais e subcontratados, incluindo marcas,
            sinais distintivos, logotipos, imagens, sons, fotografias,
            documentos, textos ou quaisquer outros elementos disponibilizados
            na Plataforma. O Cliente reconhece e aceita ainda que está
            proibido de fazer uso ou de permitir que terceiros façam uso de
            tais materiais, conteúdos e de quaisquer direitos autorais e/ou
            direitos de propriedade industrial de titularidade do BIZPIK ou de
            qualquer terceiro que tenha licenciado tais direitos ao BIZPIK,
            sem prévia e expressa autorização do respectivo titular. Por
            consequência, o Cliente declara-se ciente de que o uso indevido e
            a reprodução total ou parcial dos referidos conteúdos e
            ferramentas são proibidos, salvo mediante autorização prévia e
            expressa do respectivo titular.
            <br />
            <br />
            <strong>5.2.</strong> Autorização de uso precário. O Cliente entende e aceita que a autorização de uso da Plataforma e de suas funcionalidades e
            ferramentas não poderá ser compreendida como cessão ou atribuição
            de direitos do BIZPIK ao Cliente, mas apenas como autorização de
            uso precário da Plataforma, bem como de suas funcionalidades e
            ferramentas.
            <br />
            <br />

            <strong>5.3.</strong> Ausência de interferência no funcionamento da Plataforma. É vedado
            ao Cliente utilizar quaisquer dispositivos, softwares, e/ou
            quaisquer outros recursos que possam interferir nas atividades
            e/ou operações da Plataforma. Qualquer interferência, tentativa de
            interferência, ou qualquer outra conduta que viole ou contrarie as
            normas vigentes de direitos autorais e/ou propriedade industrial
            e/ou as proibições existentes neste Termos de Uso, poderá
            acarretar a rescisão deste Termos de Uso, suspensão do cadastro do
            Cliente por tempo indeterminado ou ainda seu cancelamento, sem
            prejuízo de responsabilização cível e criminal do Cliente, bem
            como do pagamento de indenização por perdas e danos eventualmente
            incorridos, além da não devolução dos valores eventualmente já
            pagos.
            <br />
            <br />
            <strong>5.4.</strong> Responsabilidade sobre informações e conteúdos inseridos na
            Plataforma. O Cliente é responsável por todas as informações
            enviadas por si ou por meio de seus colaboradores e representantes
            aos servidores do BIZPIK através do acesso e uso da Plataforma,
            assim como por qualquer conteúdo inserido na Plataforma,
            responsabilizando-se integralmente e ilimitadamente por quaisquer
            violações legais a direitos de terceiros, incluindo direitos de
            propriedade intelectual.
            <br />
            <br />
        </Typography>

    );
}

export default IntellectualProperty;
