/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { NavHashLink, NavHashLinkProps } from "react-router-hash-link";

const LinkButtonComponent = React.forwardRef<
  HTMLAnchorElement,
  Omit<NavHashLinkProps, "href" | "to"> & { href: string; anchor: string }
>(({ href, anchor, ...props }, ref) => {
  return (
    <NavHashLink smooth replace to={{ hash: anchor, pathname: href }} ref={ref} {...props} />
  );
});

LinkButtonComponent.displayName = "LinkButtonComponent";

export default LinkButtonComponent;
