import { styled } from "@mui/material";

const PageContentAnchor = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(-4),
  height: 0,
  width: 0,

  ":first-of-type": {
    top: "-100%"
  },

  [theme.breakpoints.up("md")]: {
    top: theme.spacing(-10)
  }
}));

export default PageContentAnchor;
