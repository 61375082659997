/* eslint-disable prettier/prettier */
import { Typography } from "@mui/material";

function IndemnityAndLimitationOfLiability() {
  return (
    <Typography>
      <strong>6. Indenidade e Limitação de Responsabilidade</strong>
      <br />
      <strong>6.1.</strong> Indenidade. Os Clientes obrigam-se a indenizar e a manter o
      BIZPIK, seus sócios, administradores, colaboradores e contratados
      livres e totalmente indenes por quaisquer gastos, perdas,
      despesas, danos, reclamações e/ou reivindicações incorridos ou
      sofridos com relação ou em decorrência de qualquer violação deste Termos de Uso
      por parte do Cliente, de seus sócios, administradores e/ou
      colaboradores. Quaisquer valores devidos aos Clientes pelo BIZPIK
      poderão ser retidos e compensados para o pagamento de débitos
      decorrentes de condenações, custas e despesas processuais, além de
      honorários advocatícios.
      <br />
      <br />
      <strong>6.2.</strong> Proteção das Informações Confidenciais. A Parte Receptora deverá
      utilizar-se do mesmo nível de cuidado que utiliza para proteger
      suas próprias informações confidenciais, tal como (que em nenhuma
      hipótese deverá ser menos do que um cuidado razoável) (i) não usar
      qualquer informação confidencial da Parte Reveladora para qualquer
      propósito fora do escopo deste contrato, (ii) salvo se expressamente autorizado pela Parte Reveladora por
      escrito, limitar o acesso das Informações Confidenciais da Parte
      Reveladora para seus empregados e de suas afiliadas, contratados e
      agentes, que necessitem de tal acesso para executar o objeto deste
      Contrato, e que tenham firmado os respectivos termos de
      confidencialidade com a Parte Receptora que contenha previsões não
      menos rígidas do que as contidas no presente instrumento. Nenhuma
      das partes deverá revelar os termos deste contrato a qualquer
      terceiro além de suas afiliadas, conselheiros legais e contadores,
      sem o prévio e expresso consentimento da outra.
      <br />
      <br />
      <strong>6.3.</strong> Limitação de responsabilidade do BIZPIK. Os Usuários reconhecem e
      aceitam que o BIZPIK não é e não será responsável:
      <br />
      <br />
      <strong>(a)</strong> por qualquer acesso indevido, atividade suspeita na conta do
      Cliente ou, ainda, pelas eventuais consequências e danos
      decorrentes da falha do Cliente em manter a confidencialidade
      da senha e/ou da falta ou atraso na comunicação o BIZPIK
      referente a eventual acesso indevido ou uso não autorizado da
      senha do Cliente por terceiros;
      <br />
      <br />
      <strong>(b)</strong> por quaisquer atos ou fatos decorrentes de força maior ou caso
      fortuito, incluindo, mas não se limitando, àqueles casos em
      que o cumprimento de obrigações aqui previstas se tornar
      inviável por consequência direta ou indireta de suspensão de
      serviços de terceiros (como eletricidade, telefonia ou
      internet), incêndios, tempestade, lei, regulamento, greves,
      pandemias, insurreição ou revolta, guerra, ou qualquer outra
      causa que for inevitável ou além do controle razoável do
      BIZPIK;
      <br />
      <br />
      <strong>(c)</strong> por qualquer dano sofrido pelo Cliente em virtude da remoção
      unilateral de quaisquer dados e informações que inseriu na
      Plataforma, do cancelamento do seu cadastro, bem como da
      preservação ou fornecimento de informações a seu respeito,
      incluindo aquelas relativas à sua identificação, localização e
      registros de conexão e/ou de acesso à Plataforma, desde que em
      cumprimento deste Termos de Uso, da Política de Privacidade
      e/ou de ordens judiciais ou administrativas;
      <br />
      <br />
      <strong>(d)</strong> por eventual modificação, suspensão, interrupção ou
      descontinuação da Plataforma;
      <br />
      <br />
      <strong>(e)</strong> por qualquer declaração ou garantia, expressa ou implícita ou
      realizada através de negociações com os Clientes, incluindo,
      mas sem limitação: (i) à garantia implícita de adequação a um
      determinado fim ou à não-violação de direitos de terceiros; e
      (ii) a qualquer garantia com relação à qualidade, precisão,
      eficácia, tempestividade ou integralidade dos serviços
      oferecidos a partir da Plataforma, ou de que o acesso do
      Cliente ou o uso pelo Cliente dos serviços da Plataforma serão
      livres de erros, de vírus, de falhas, ininterruptos ou
      atenderão a todos os requisitos do Cliente;
      <br />
      <br />
      <strong>(f)</strong> por pagamentos e/ou recebimentos errôneos e/ou em atraso
      realizados ou recebidos pelo Cliente em relação a
      fornecedores, clientes, funcionários, agentes, ou qualquer
      outra pessoa, cabendo ao Cliente conferir os valores apontados pela Plataforma,
      inclusive comissões, sendo de sua responsabilidade apurar e
      confirmar os valores corretos e realizar verificações
      periódicas nos cálculos e lançamentos informados pelo sistema
      para fins de verificar a sua correção. Os pagamentos e
      recebimentos realizados pelo Cliente, sem a devida
      conferência, não serão de responsabilidade do BIZPIK;
      <br />
      <br />
      <strong>(g)</strong> por eventuais prejuízos decorrentes de lucros cessantes, dano
      moral, dano à imagem, perda de oportunidade comercial e quebra
      de expectativa; e
      <br />
      <br />
      <strong>(h)</strong> por qualquer ato, fato ou evento que não acarrete ao Cliente o
      dever de realizar um desembolso efetivo de recursos.
      <br />
      <br />
      <strong>6.3.</strong> É de responsabilidade única e exclusiva do Cliente verificar,
      atualizar e adaptar o sistema, através das parametrizações
      necessárias, para seu ramo de atividade e porte de
      companhia.
      <br />
      <br />

      <strong>6.4.</strong> Limite máximo de indenização: Nas hipóteses em que o BIZPIK
      seja considerada responsável por danos comprovadamente causados
      pela Plataforma, a indenização devida ao Cliente estará limitada
      à quantia correspondente ao valor de 3 (três) mensalidades do
      Plano contratado pelo Cliente na época da violação ou a 10% do
      valor total da contratação. Caso o Cliente não esteja de acordo
      com esta limitação, deverá interromper a utilização da
      Plataforma imediatamente.
      <br />
      <br />
    </Typography>

  );
}

export default IndemnityAndLimitationOfLiability;
