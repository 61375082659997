/* eslint-disable prettier/prettier */
import { Typography } from "@mui/material";

function PlatformAvailabilityAndMaintenance() {
    return (
        <Typography>
            <strong>4. Disponibilidade e Manutenção da Plataforma</strong>
            <br />
            <strong>4.1.</strong> Tempo de disponibilidade da Plataforma. O BIZPIK envidará os seus
            melhores esforços para que a Plataforma opere continuamente e para
            que o sistema esteja disponível no regime de 24x7x365 (24 horas
            por dia, 7 dias por semana, 365 dias por ano) por no mínimo 90%
            (noventa por cento) do tempo de operação de cada mês.
            <br />
            <br />

            <strong>4.1.1.</strong> Interrupções que não afetam o tempo de disponibilidade da
            Plataforma. Os períodos de funcionamento afetados por
            manutenções programadas, manutenções preventivas, falta de
            energia elétrica, interrupção ou suspensão da prestação dos
            serviços de telecomunicações, ocorrências de falhas no sistema
            de transmissão, atualizações do sistema, ou casos fortuitos e
            de força maior, estarão excluídos do tempo de operação e não
            configurarão qualquer tipo de responsabilidade ou obrigação de
            indenizar. Em caso de manutenções urgentes, o BIZPIK envidará
            seus melhores esforços para comunicar os Clientes a respeito
            da possível suspensão ou interrupção dos serviços com
            antecedência razoável para não prejudicar as atividades do
            Cliente.
            <br /><br />
            <strong>4.2.</strong> Assistência técnica. O BIZPIK oferecerá aos Usuários assistência
            técnica relacionada a falhas e defeitos do funcionamento da
            Plataforma, mas não será responsável pela operação individual dos
            dispositivos do Cliente.
            <br /><br />
            <strong>4.3.</strong> Suspensão de uso da Plataforma. O BIZPIK se reserva o direito de
            suspender o acesso do Cliente à Plataforma sem notificação prévia
            caso seja verificado mau uso, causado por volume muito acima de
            médias ou operações consideradas inapropriadas para o bom uso e segurança da Plataforma.
            <br /><br />
            <strong>4.4.</strong> Descontinuidade da Plataforma. O BIZPIK poderá suspender,
            interromper ou descontinuar a Plataforma, a qualquer tempo, a seu
            critério e conforme lhe convier, ainda que fora das hipóteses
            previstas neste Termos de Uso, mediante aviso prévio com prazo
            razoável aos Clientes, sem que isso configure nenhum tipo de
            responsabilidade para o BIZPIK.
            <br /><br />
        </Typography>
    );
}

export default PlatformAvailabilityAndMaintenance;
