import { RouteProps } from "react-router-dom";

import Main from "../pages/Main/index";
import Policy from "../pages/Policy/index";
import Terms from "../pages/Terms/index";
// import AvecWebsite from "../pages/Partner/AvecWebsite/index";
import AlpopWebsite from "../pages/Partner/AlpopWebsite/index";
// import EduzzWebsite from "../pages/Partner/EduzzWebsite/index";
import { webSiteInfo } from "../payload/data";
import InitializationFail from "../pages/InitializationFail";
import EduzzWebsite from "../pages/Partner/EduzzWebsite";

const routes: RouteProps[] = [
  { path: "/error", component: InitializationFail },
  { path: "/policy", exact: true, component: Policy },
  { path: "/terms", exact: true, component: Terms },
  {
    path: webSiteInfo.bizpik.path,
    component: Main,
    exact: true
  },
  {
    path: webSiteInfo.alpop.path,
    exact: true,
    component: AlpopWebsite
  },
  // {
  //   path: webSiteInfo.avec.path,
  //   exact: true,
  //   component: AvecWebsite,
  //   RouteComponent: NavigationRoute
  // },
  {
    path: webSiteInfo.eduzz.path,
    exact: true,
    component: EduzzWebsite
  }
];

export default routes;
