import { ButtonBase, ListItemIcon, Menu, MenuItem } from "@mui/material";
import React from "react";

import useAppTranslations from "../../../hooks/useAppTranslations";
import { ITranslationDictionary } from "../../../types/i18n";

export default function LanguageButton() {
  const { t, lang, langs } = useAppTranslations();
  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleSelectLanguage = (id: keyof ITranslationDictionary) => {
    lang.change(id);
    setAnchorElMenu(null);
  };

  return (
    <>
      <ButtonBase
        className="rounded-full w-8 h-8"
        sx={{
          backgroundImage: `url("${lang.iconUrl}")`,
          backgroundOrigin: "padding-box",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center"
        }}
        onClick={handleOpenMenu}
      />

      <Menu
        id="appbar-language-menu"
        open={Boolean(anchorElMenu)}
        anchorEl={anchorElMenu}
        onClose={handleCloseMenu}
        keepMounted
        PopoverClasses={{ paper: "rounded-full mt-3" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
      >
        {langs.map(x => (
          <MenuItem onClick={() => handleSelectLanguage(x.id)} className="p-2" key={x.id}>
            <ListItemIcon className="justify-center">
              <img src={x.iconUrl} alt={t(x.tsrc)} />
            </ListItemIcon>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
