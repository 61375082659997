import { Box, Grid, Stack, Typography } from "@mui/material";

import GridContainer from "../../../../components/GridContainer";
import Translate from "../../../../components/Translate";
import colors from "../../../../themes/colors";

import { IPageConfigProducerPerson } from "../../../../types/page";
import ProducerPersonCard from "../../../../components/ProducerPersonCard";

interface ProducerSectionProps {
  title: string;
  subtitle: string;
  youtubeLogo: string;
  instagramLogo: string;
  people: IPageConfigProducerPerson[];
}

export default function ProducerSection({
  title,
  subtitle,
  youtubeLogo,
  instagramLogo,
  people
}: ProducerSectionProps) {
  return (
    <Box component="section">
      <GridContainer>
        <div className="absolute -top-16 md:-top-40 h-0 w-0" id="producers" />

        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10} justifyContent="center">
            <Typography
              variant="button"
              color={theme => theme.palette.text.secondary}
              children={<Translate customKey={subtitle} />}
            />

            <Typography
              color={colors.dodgerBlue}
              sx={{ fontWeight: 700, fontSize: 40 }}
              children={<Translate customKey={title} />}
              className="mb-12"
            />

            <Box className="overflow-x-scroll w-full pb-4">
              <Stack component="ul" direction="row" spacing={3}>
                {people.map(person => (
                  <ProducerPersonCard
                    key={person.name}
                    image={person.image}
                    name={person.name}
                    socialMedias={person.socialMedias.map(x => {
                      const image = x.name === "Instagram" ? instagramLogo : youtubeLogo;

                      return {
                        image,
                        name: x.name,
                        url: x.url
                      };
                    })}
                  />
                ))}
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </GridContainer>
    </Box>
  );
}
