import { ITranslationDictionary } from "../types/i18n";
import enUS from "./locale/en-US.json";
import ptBR from "./locale/pt-BR.json";
import esES from "./locale/es-ES.json";

const languages: ITranslationDictionary = {
  pt: {
    tsrc: "i18n:translation_lang_portuguese",
    iconUrl: "/assets/language/pt.png",
    resource: ptBR
  },

  en: {
    tsrc: "i18n:translation_lang_english",
    iconUrl: "/assets/language/en.png",
    resource: enUS
  },

  es: {
    tsrc: "i18n:translation_lang_spanish",
    iconUrl: "/assets/language/es.png",
    resource: esES
  }
};

export default languages;
