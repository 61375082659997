/* eslint-disable prettier/prettier */
import { Typography } from "@mui/material";

function PlatformAndResponsibilities() {
    return (
        <Typography>
            <strong>3. Plataforma e Responsabilidades</strong>
            <br />
            <strong>3.1.</strong>
            Condutas proibidas na utilização da Plataforma. O Cliente se
            obriga a utilizar a Plataforma de boa-fé e sem a intenção de
            praticar atos ilícitos ou causar danos a terceiros. Sem prejuízo
            ou limitação às demais obrigações e restrições previstas neste
            Termos de Uso e na legislação pertinente, o Cliente obriga-se a
            não praticar e a fazer com que quaisquer pessoas autorizadas que
            tenham acesso à Plataforma não pratiquem nenhuma das seguintes
            condutas:
            <br />
            <br />
            <strong>(a)</strong> distribuir, reproduzir, modificar, vender, alugar ou de
            qualquer forma explorar economicamente a Plataforma, seus
            dados, informações, código-fonte, marcas, direitos autorais
            relacionados, ou qualquer outro conteúdo veiculado pelo
            BIZPIK, exceto se com a autorização prévia e por escrito do
            BIZPIK;
            <br /><br />
            <strong>(b)</strong> realizar quaisquer obras derivadas ou traduções da Plataforma;
            <br /><br />
            <strong>(c)</strong> decifrar, decodificar, desmontar, realizar engenharia reversa,
            ou tentar deduzir qualquer parte do código-fonte, ideia
            subjacente ou algoritmos relacionados à Plataforma;
            <br /><br />
            <strong>(d)</strong> investigar ou testar a vulnerabilidade de qualquer sistema ou
            rede relacionada à Plataforma;
            <br /><br />

            <strong>(e)</strong> acessar, adulterar ou usar áreas não públicas da Plataforma ou
            quaisquer áreas compartilhadas para as quais o Cliente não
            possui autorização de acesso;
            <br />
            <br />

            <strong>(f)</strong> causar danos, interferir ou perturbar outros Clientes e/ou
            servidores de hospedagem ou rede;
            <br />
            <br />

            <strong>(g)</strong> corromper, danificar, limitar ou interferir na função
            apropriada e normal de softwares, hardwares, ferramentas ou
            equipamentos relacionados à Plataforma;
            <br />
            <br />

            <strong>(h)</strong> obter acesso não autorizado a sistemas, informações, senhas,
            ou outros dados do BIZPIK e/ou de quaisquer Usuários ou
            terceiros;
            <br />
            <br />

            <strong>(i)</strong> interferir no funcionamento normal da Plataforma, incluindo
            por meio de envio ou transmissão de qualquer arquivo malicioso que
            contenha vírus, worms, cavalos de
            Tróia ou qualquer outra espécie de programação ou comando que
            possa contaminar, destruir ou interferir no bom funcionamento
            da Plataforma;
            <br />
            <br />

            <strong>(j)</strong> violar ou contornar medidas de segurança e/ou autenticação do
            BIZPIK para restringir ou prevenir o acesso a ferramentas,
            contas, sistemas e redes relacionadas à Plataforma; e
            <br />
            <br />

            <strong>(k)</strong> utilizar os serviços contratados para fins de infração à
            legislação brasileira, para fins de causar danos a terceiros
            ou para fins de armazenar documentos que estejam relacionados
            a ilícitos cíveis ou penais.
            <br /><br />

            <strong>3.2.</strong> Acesso à Plataforma. A Plataforma é disponibilizada através da
            rede mundial de computadores, sendo de inteira responsabilidade
            do Cliente o dispositivo pelo qual realiza o acesso, bem como
            sua conexão própria com a internet, englobando, provedor de
            acesso, modem, e demais intermediários envolvidos na conexão
            particular do Cliente. O BIZPIK não possui qualquer obrigação
            sobre os dispositivos e conexões particulares do Cliente.
            <span className="hidden" style={{ backgroundColor: "#ff0" }}>O Cliente assume a obrigação de não acessar a Plataforma através de conexão indireta aos servidores do BIZPIK, não podendo fazer uso
                de tecnologias de proxy ou virtual private network (VPN) ou
                qualquer outra tecnologia que impeça o completo conhecimento por
                parte do BIZPIK do número de IP (Internet Protocol) utilizado na
                conexão, ou que mostre número de IP diferente do computador de
                origem do acesso, sob pena de cancelamento do Plano contratado
                por violação às regras de segurança dos servidores do BIZPIK
            </span> O Cliente é responsável por realizar o acesso à Plataforma
            apenas em computadores seguros e privados, que estejam livres de
            vírus e que sejam confiáveis do ponto de vista de segurança dos
            dados inseridos, não havendo responsabilidade do BIZPIK por
            qualquer forma de indenização em razão de acesso em computadores públicos, como, por exemplo, em lan houses.
            <br /><br />
            <strong>3.3.</strong> SaaS: O BIZPIK deverá disponibilizar a plataforma nos termos do
            presente Contrato, durante cada período de contratação. O CLIENTE
            declara concordar que a contratação da plataformo BIZPIK não
            contém ou garante futuras funcionalidades ou características da
            plataforma, independentemente de notas orais ou escritas emitidas
            pelo BIZPIK.
            <br /><br />
            <strong>3.4.</strong> Cadastro de Contas/Perfis de Usuários adicionais à plataforma: os
            Serviços SaaS adquiridos como “conta/perfil de usuário adicional”
            não poderão ser acessados por mais usuários do que o especificado.
            <br /><br />

            <strong>3.5.</strong> Responsabilidades do BIZPIK: O BIZPIK deverá: (i) fornecer ao
            CLIENTE suporte da plataforma (leia-se suporte a falhas) sem
            qualquer custo adicional; (ii) fornecer os Serviços SaaS em
            consonância com as leis e regulamentos aplicáveis.
            <br /><br />

            <strong>3.6.</strong> Proteção dos Dados Pessoais: As Partes declaram que irão tratar
            dados pessoais em conformidade com a Lei Geral de Proteção de
            Dados (Lei 13.709/18) e outras leis aplicáveis às atividades das
            Partes relacionadas à proteção de dados e privacidade e garantir
            que seus empregados, agentes e subcontratados também o façam. As
            Partes garantem que todos os dados pessoais eventualmente
            compartilhados no âmbito deste Contrato foram obtidos legalmente
            de acordo com os requisitos da Lei Geral de Proteção de Dados (Lei
            13.709/18) e que possuem o direito de tratá-los e de
            compartilhá-los com a outra Parte. Para mais informações, acesse
            nossa Política de Privacidade.
            <br /><br />

            <strong>3.7.</strong> Responsabilidades do CLIENTE. O CLIENTE deverá: (i)
            responsabilizar-se pelo cumprimento do presente instrumento por
            parte dos Usuários; (ii) responsabilizar-se pela precisão,
            qualidade e legalidade dos seus dados, bem como pela legalidade da forma
            como tais dados foram adquiridos e tratados, inclusive quando da
            transferência para o BIZPIK; (iii) utilizar esforço comercial
            razoável para prevenir acessos/ utilização não autorizados da
            plataforma, e notificar o BIZPIK imediatamente na hipótese de
            qualquer uso não autorizado;
            <br /><br />

            <a
                href="https://www.whatsapp.com/legal/business-solution-terms"
                target="_blank" rel="noreferrer"
            >(iv) utilizar-se da plataforma exclusivamente de acordo com a
                legislação e normas regulamentares vigentes; (v) Respeitar e
                cumprir todas as disposições da Meta (https://www.whatsapp.com/legal/business-solution-terms)
            </a>

            <a
                href="https://www.whatsapp.com/legal/business-terms-for-service-providers/"
                target="_blank" rel="noreferrer"
            >https://www.whatsapp.com/legal/business-terms-for-service-providers/
            </a>

            <a
                href="https://developers.facebook.com/docs/whatsapp/on-premises/guides"
                target="_blank" rel="noreferrer"
            >https://www.whatsapp.com/legal/business-policy </a>
            <a
                href="https://www.facebook.com/legal/commercial_terms"
                target="_blank" rel="noreferrer"
            >
                https://developers.facebook.com/docs/whatsapp/on-premises/guides </a>

            <a
                href="https://www.facebook.com/legal/commercial_terms"
                target="_blank" rel="noreferrer"
            >
                https://www.facebook.com/legal/commercial_terms
            </a>
            <a
                href="https://www.facebook.com/legal/terms"
                target="_blank" rel="noreferrer"
            >
                https://www.facebook.com/legal/terms
            </a>
            <br /><br />

            O CLIENTE não deverá: (i)disponibilizar a plataforma para
            terceiros que não os Usuários; (ii) vender, revender, alugar ou
            arrendar a plataforma; (iii) utilizar a plataforma para armazenar
            ou transmitir materiais de conteúdo prejudicial, difamatório ou de
            qualquer modo ilegal ou desonesto, ou ainda armazenar ou
            transmitir materiais que violem os direitos de privacidade de
            qualquer terceiro; (iv) utilizar a plataforma para transmitir
            códigos mal-intencionados; (v) interferir ou romper a integridade
            ou performance da plataforma ou dados de terceiros nestes
            contidos; ou (vi) empenhar-se em obter acesso não autorizado à
            plataforma ou ainda aos seus respectivos sistemas e redes.
            <br /><br />


            <strong>3.4.</strong> Prestação de serviços de Implementação de software e
            Consultoria. O CLIENTE, além dos serviços SaaS previstos em
            cláusulas anteriores poderá contratar serviços de Consultoria,
            com escopo apresentado em proposta específica. Para contratar os
            serviços de Consultoria previstos nesta cláusula, o CLIENTE
            deverá entrar em contato com a equipe BIZPIK. Os serviços de Consultoria
            serão cobrados em conformidade com o projeto e proposta a serem
            definidos.
            <br /><br />

        </Typography>
    );
}

export default PlatformAndResponsibilities;
