import { Box, Grid, List, ListItem, ListItemAvatar, Typography } from "@mui/material";

import GridContainer from "../../../components/GridContainer";
import Translate from "../../../components/Translate";
import colors from "../../../themes/colors";
import { IPageConfigClientCompany } from "../../../types/page";

interface ClientSectionProps {
  companies: IPageConfigClientCompany[];
  title: string;
}

export default function ClientSection({ title, companies }: ClientSectionProps) {
  return (
    <Box component="section">
      <GridContainer>
        <div className="absolute -top-16 md:-top-40 h-0 w-0" id="clients" />

        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10} justifyContent="center">
            <Typography
              variant="button"
              color={theme => theme.palette.text.secondary}
              children={<Translate i18nKey="section:clients:subtitle" />}
            />

            <Typography
              color={colors.dodgerBlue}
              sx={{ fontWeight: 700, fontSize: 40 }}
              children={<Translate customKey={title} />}
              className="mb-12"
            />

            <Box className="overflow-x-scroll w-full pb-4 lg:overflow-x-visible lg:pb-0">
              <List
                component="ul"
                sx={{ "& .MuiListItem-root": { px: 0, width: "auto" } }}
                disablePadding
                className="flex flex-row justify-between min-w-[68rem]"
              >
                {companies.map(company => (
                  <ListItem key={company.name}>
                    <ListItemAvatar>
                      <img src={company.image} alt={company.name} />
                    </ListItemAvatar>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
        </Grid>
      </GridContainer>
    </Box>
  );
}
