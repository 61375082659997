import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import languages from "./languages";
import { I18N_COMMON_NS } from "./namespace";

const resources = {
  pt: { [I18N_COMMON_NS]: languages.pt.resource },
  en: { [I18N_COMMON_NS]: languages.en.resource },
  es: { [I18N_COMMON_NS]: languages.es.resource }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // debug: process.env.NODE_ENV !== "production",
    defaultNS: I18N_COMMON_NS,
    ns: [I18N_COMMON_NS],
    fallbackLng: "en", // linguagem padrão
    keySeparator: ":",
    resources,
    interpolation: {
      escapeValue: false // not needed for react!!
    }
  });

export default i18n;
