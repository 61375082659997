/* eslint-disable prettier/prettier */
import { Typography } from "@mui/material";

function UserRegistration() {
    return (
        <Typography >
            <strong>2. Cadastro de Usuários</strong>
            <br />
            <strong>2.1.</strong>

            Informações cadastrais. Para utilizar as funcionalidades
            disponibilizadas pela Plataforma, o Cliente deve fornecer
            informações específicas a respeito de si e/ou da pessoa física ou
            jurídica que representa. Ao aderir a este Termos de Uso, o Cliente
            declara e garante que forneceu e sempre fornecerá ao BIZPIK
            informações exatas, atualizadas e verdadeiras. O Cliente
            declara-se ciente de que a utilização de dados falsos,
            desatualizados, inválidos, incorretos ou de terceiros poderá
            acarretar o término antecipado da vigência deste Termos de Uso,
            com o consequente impedimento da utilização da Plataforma, sem
            prejuízo de responsabilização civil e criminal do Cliente, bem
            como do pagamento de indenização por perdas e danos sofridos pelo BIZPIK.
            <br />
            <br />

            <strong>2.1.1.</strong>

            Atualização de informações cadastrais. O Cliente obriga-se a o
            atender prontamente quaisquer solicitações referentes à
            atualização cadastral, sob pena de suspensão ou cancelamento
            de seu cadastro, sem que tal suspensão ou cancelamento gere ao
            Cliente qualquer direito de indenização ou reembolso.
            <br />
            <br />
            <strong>2.1.2.</strong>

            Bloqueio do acesso por inconsistência em informações
            cadastrais. O Cliente reconhece e aceita que nas hipóteses do
            BIZPIK constatar a existência de informações cadastrais
            incorretas, inverídicas, desatualizadas ou inexatas, e/ou de
            (b) o Cliente não disponibilizar informações e/ou documentos
            eventualmente requeridos pelo BIZPIK, o BIZPIK poderá, a seu
            critério exclusivo e discricionário, bloquear por tempo
            indeterminado o cadastro do Cliente. Em decorrência do
            bloqueio cadastral, o Cliente estará temporariamente impedido
            de acessar e utilizar as funcionalidades da Plataforma, sem
            que nenhuma responsabilidade possa ser imputada ao BIZPIK.
            <br />
            <br />

            <strong>2.2.</strong>

            Ausência de impedimentos para adesão a este Termos de Uso. Ao
            aderir a este Termos de Uso, o Cliente declara não ter nenhum
            impedimento, inexistindo contrato ou vínculo de qualquer natureza
            com terceiros ou qualquer decisão ou restrição imposta por uma
            autoridade governamental ou judiciária que de qualquer forma o
            impeça de aderir a este Termos de Uso. O Cliente declara possuir
            capacidade jurídica para contratação da Plataforma. Caso o Cliente
            venha a realizar contratação em nome de pessoa física ou jurídica
            para a qual não possua capacidade de representação, o Cliente
            estará pessoalmente responsável às obrigações deste instrumento,
            incluindo o pagamento dos serviços.
            <br />
            <br />
            <strong>2.3.</strong>

            Sigilo das credenciais de acesso à Plataforma. O Cliente é
            responsável por manter em sigilo as credenciais de acesso à

            Plataforma (nome de usuário, senha, entre outros). Caso o acesso à
            Plataforma seja efetuado por terceiros em razão da negligência do
            Cliente em relação aos seus dados de acesso à Plataforma, o BIZPIK
            fica isento de qualquer responsabilidade relacionada à privacidade
            e segurança das informações e arquivos do Cliente.
            <br />
            <br />
            <strong>2.3.1.</strong>

            Responsabilidade por perfis/contas criados pelo Usuário para
            uso da Plataforma. O Cliente poderá criar diferentes perfis na
            Plataforma para que seus colaboradores possam usar a
            Plataforma, tendo os perfis acesso a diferentes
            funcionalidades a depender das configurações escolhidas pelo
            Cliente. O Cliente será o único e exclusivo responsável por
            toda a atividade realizada na Plataforma por meio da
            utilização dos perfis que criou, devendo informar, orientar e
            fazer com que seus colaboradores observem todas as regras
            previstas neste Termos de Uso.
            <br />
            <br />
            <strong>2.4.</strong>

            Comunicação com o Cliente. O Cliente declara que o e-mail e
            telefone informados ao BIZPIK por meio da Plataforma são meios
            igualmente eficazes, válidos e suficientes para recebimento de
            comunicados relacionados à sua conta, bem como para informações de
            cancelamento ou suspensão de uso da Plataforma. O Cliente
            reconhece e autoriza também a utilização da página inicial de
            acesso à Plataforma como meio eficaz, válido e suficiente para a
            divulgação de assuntos relacionados à contratação, inclusive em
            relação a eventual reajuste de preços ou alteração de
            funcionalidades e dos limites de armazenamento.
            <br />
            <br />
        </Typography>
    );
}

export default UserRegistration;
