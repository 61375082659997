/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { SxProps, Theme } from "@mui/material";
import _ from "lodash";

export function mergeMUISxProps(
  ...sxValues: (SxProps<Theme> | undefined | null)[]
): SxProps<Theme> {
  return _.compact(_.flatten(sxValues));
}

export default {
  mergeMUISxProps
};
