/* eslint-disable no-promise-executor-return */
import axios from "axios";
import { IApiResponse, IPageConfigResponseModel } from "../types/api";

const baseURL = process.env.REACT_APP_API_URL;

export const webSiteInfo = {
  bizpik: {
    id: 0,
    name: "BizPik",
    path: "/",
    loadingImage: "/assets/bizpik-logo.svg"
  },

  // avec: {
  //   id: 1,
  //   name: "Avec",
  //   path: "/avec",
  //   loadingImage: "https://bizpik-website.s3.amazonaws.com/assets/partners/alpop/loading.svg"
  // },

  eduzz: {
    id: 2,
    name: "Eduzz",
    path: "/eduzz",
    loadingImage: "https://bizpik-website.s3.amazonaws.com/assets/partners/eduzz/loading.svg"
  },

  alpop: {
    id: 3,
    name: "Alpop",
    path: "/alpop",
    loadingImage: "https://bizpik-website.s3.amazonaws.com/assets/partners/alpop/loading.svg"
  }
};

export async function fetchWebSiteContent(
  partnerId = webSiteInfo.bizpik.id
): Promise<IPageConfigResponseModel> {
  if (!baseURL) {
    throw Error("missing Bizpik API base url to request website content.");
  }

  // const url = new URL(`/api/pages/${partnerId}`, baseURL);
  const url = `${baseURL}pages/${partnerId}`;

  await new Promise(resolve => setTimeout(resolve, 1000));

  const response = await axios.get(url, {
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*"
    }
  });

  if (response.status !== 200) {
    throw Error(`error during page config request. reason: ${response.statusText}`);
  }

  const model: IApiResponse<IPageConfigResponseModel | null> = await response.data;

  if (!model.success) {
    throw Error(`error during page config request. reason: ${model.message}`);
  }

  if (model.data === null) {
    throw Error("error during page config request. reason: no data found");
  }

  model.data.translations = model.translations ?? model.data.translations;

  return model.data;
}
