import { Box, ButtonBase, Theme } from "@mui/material";
import React from "react";
import LinkButtonComponent from "./LinkButtonComponent";

interface LinkButtonProps {
  href: string;
  anchor?: string;
  className?: string;
  refresh?: boolean;
  enableUnderscore?: boolean;
  underscoreColor?: (theme: Theme) => string;
  newTab?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
}
const ButtonBaseAsAny = ButtonBase as any;

export default function LinkButton({
  enableUnderscore,
  className,
  underscoreColor,
  children,
  newTab,
  onClick,
  href,
  refresh = false,
  anchor
}: React.PropsWithChildren<LinkButtonProps>) {
  return (
    <Box>
      <ButtonBaseAsAny
        sx={[
          {
            fontWeight: (theme: Theme) => theme.typography.fontWeightBold,
            color: (theme: Theme) => theme.palette.text.primary,
            lineHeight: "2rem",
            borderStyle: "solid",
            borderColor: underscoreColor ?? (theme => theme.palette.background.default),
            borderBottomWidth: enableUnderscore ? 2 : 0
          }
        ]}
        onClick={onClick}
        className={className}
        href={href}
        anchor={anchor}
        replace={refresh}
        target={newTab ? "_blank" : undefined}
        disableTouchRipple
        LinkComponent={LinkButtonComponent}
        children={children}
      />
    </Box>
  );
}
