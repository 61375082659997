/* eslint-disable prettier/prettier */
import { Typography } from "@mui/material";

function Initial() {
    return (
        <Typography>
            Este instrumento contempla os Termos e Condições de Uso da plataforma Bizpik. Antes de
            acessar e usar a Plataforma, certifique-se de que compreendeu todas as condições
            estabelecidas neste instrumento. A utilização dos serviços oferecidos através da
            plataforma indica expressamente que você concorda com todos os termos e condições contidos
            neste instrumento e com as disposições legais aplicáveis à espécie. Eventuais dúvidas
            deverão ser sanadas em contato prévio com a equipe da Bizpik. Após ler, compreender e
            concordar com todo o conteúdo deste Termos e Condições de Uso, você poderá aderir a ele.
            Caso você não concorde com este Termos e Condições de Uso, você deverá imediatamente
            suspender o uso da Plataforma.
            <br />
            <strong>
                A utilização da Plataforma implica a aceitação plena, irrestrita e sem reservas de todos
                os termos e condições previstos neste documento, inclusive no caso de eventuais revisões
                ou publicação de novas versões.
            </strong>
            <br />
            <br />
            <strong> Contrato de Assinatura &amp; Serviços de Software </strong>
            <br />
            Por este instrumento particular de contrato (“Contrato”), na melhor forma do Direito, por
            estarem de acordo com todas as cláusulas que se seguem, tendo por partes.
            <br />
            <br />
            <strong>a)</strong> BIZPIK SOLUÇÕES AVANÇADAS EM TECNOOLOGIA LTDA, pessoa jurídica, inscrita no CNPJ/MF sob o
            nº. 51.317.582/0001-75, com sede à Rua Cardoso de Almeida, nº 634, Conjunto 11, 1ºandar,
            Bairro Perdizes, CEP 05013-000, Município de São Paulo/SP, denominada “BIZPIK”; e,
            <br />
            <br />
            <strong>b)</strong> CLIENTE, qualificado de acordo com o cadastro eletrônico realizado no website
            <a href="https://bizpik.com.br/" target="_blank" rel="noreferrer">
                https://bizpik.com.br/
            </a>
            , denominado “CLIENTE”.
            <br />
            <br />
            BIZPIK e CLIENTE referidas, individual e indistintamente, como “Parte” e, em conjunto,
            como “Partes”. Para boa e valiosa consideração as partes ajustam o que segue:
            <br />
            <br />
        </Typography>
    );
}

export default Initial;
