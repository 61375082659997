import { ITranslationDictionary } from "../types/i18n";
import useAppTranslations from "./useAppTranslations";

interface AppFormatterState {
  formatCurrency(value: number, lang?: keyof ITranslationDictionary): string;
}

export default function useAppFormatter(): AppFormatterState {
  const { lang: appLang } = useAppTranslations();

  function formatCurrency(value: number, lang = appLang.current): string {
    return new Intl.NumberFormat(lang, {
      style: "currency",
      currency: ((lang === "pt" && "BLR") ||
        (lang === "en" && "USD") ||
        (lang === "es" && "EUR")) as string,
      maximumFractionDigits: 0
    })
      .format(value)
      .replace("BLR", "R$");
  }

  return {
    formatCurrency
  };
}
