/* eslint-disable react/jsx-props-no-spreading */
import _ from "lodash";
import { List, ListProps } from "@mui/material";

import { mergeMUISxProps } from "../utils/material";

interface CheckBoxListProps {
  checkBoxcolor: string;
  childrenProps?: Partial<{ muiList: ListProps }>;
}

export default function CheckBoxList({
  checkBoxcolor,
  children,
  childrenProps
}: React.PropsWithChildren<CheckBoxListProps>) {
  return (
    <List
      sx={mergeMUISxProps(
        {
          height: "300px",
          "& .MuiListItem-root:not(:last-of-type)": {
            paddingBottom: "2px"
          },

          "& .MuiListItem-root .MuiListItemIcon-root": {
            minWidth: 0,
            marginRight: theme => theme.spacing(1)
          },

          "& .MuiListItem-root .MuiListItemText-root .MuiTypography-root": {
            fontWeight: 500,
            letterSpacing: "0px !important"
          },

          "& .MuiListItem-root .MuiListItemIcon-root svg": {
            width: "1.5rem",
            height: "1.5rem"
          },

          "& .MuiListItem-root .MuiListItemIcon-root svg polyline": {
            color: checkBoxcolor
          }
        },
        childrenProps?.muiList?.sx
      )}
      {...(_.omit(childrenProps, "sx") ?? {})}
      children={children}
    />
  );
}
