import React from "react";
import { Button, IconButton, ListItem, Menu } from "@mui/material";
import { BiMenu } from "react-icons/bi";

import colors from "../../../themes/colors";
import MenuLinkButton from "./MenuLinkButton";
import Translate from "../../../components/Translate";
import { INavigationLink } from "../../../types/nav";

interface HamburguerMenuProps {
  links: INavigationLink[];
}

export default function HamburguerMenu({ links }: HamburguerMenuProps) {
  const [anchorElMenu, setAnchorElMenu] = React.useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleMenuItemSelect = () => {
    setAnchorElMenu(null);
  };

  return (
    <>
      <IconButton
        size="small"
        sx={{ color: theme => theme.palette.grey[900] }}
        className="ml-4 -mr-3"
        onClick={handleOpenMenu}
      >
        <BiMenu size={32} />
      </IconButton>

      <Menu
        id="appbar-hamburguer-menu"
        open={Boolean(anchorElMenu)}
        anchorEl={anchorElMenu}
        onClose={handleCloseMenu}
        closeAfterTransition
        PopoverClasses={{ paper: "mt-3 min-w-[14rem]" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
      >
        <ListItem className="mb-2">
          <Button
            href="https://sistema.bizpik.com.br"
            fullWidth
            sx={{
              backgroundColor: colors.dodgerBlue,
              textTransform: "none",
              fontSize: 14,
              lineHeight: "22px",
              height: "auto",
              ":hover": {
                backgroundColor: colors.dodgeBlueDarker
              }
            }}
            className="py-1.5"
            variant="contained"
            children={<Translate i18nKey="label:login" />}
          />
        </ListItem>

        {links.map(link => (
          <MenuLinkButton
            key={link.anchor}
            children={<Translate customKey={link.label} />}
            href={link.path}
            anchor={
              !link.anchor || link.anchor.startsWith("#") ? link.anchor : `#${link.anchor}`
            }
            onClick={handleMenuItemSelect}
          />
        ))}

        <MenuLinkButton
          children={<Translate i18nKey="nav:policy" />}
          onClick={handleMenuItemSelect}
          href="policy"
        />

        <ListItem className="mt-2">
          <Button
            onClick={handleMenuItemSelect}
            href="https://register.bizpik.com.br/company"
            fullWidth
            sx={{
              backgroundColor: colors.violet,
              textTransform: "none",
              fontSize: 14,
              lineHeight: "22px",
              height: "auto",
              ":hover": {
                backgroundColor: colors.violetDarker
              }
            }}
            className="py-1.5"
            variant="contained"
            children={<Translate i18nKey="label:test" />}
          />
        </ListItem>
      </Menu>
    </>
  );
}
