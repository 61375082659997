import { Box, styled } from "@mui/material";

const PageContent = styled(Box)(({ theme }) => ({
  overflowX: "hidden",

  "& > section.MuiBox-root": {
    backgroundColor: theme.palette.grey[100],

    [theme.breakpoints.down("sm")]: {
      paddingX: theme.spacing(4)
    }
  },

  "& > section.MuiBox-root:nth-of-type(2n)": {
    backgroundColor: theme.palette.grey[200]
  },

  "& > section.MuiBox-root:nth-of-type(4n - 1)": {
    backgroundColor: theme.palette.grey[300]
  }
}));

export default PageContent;
