/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useHistory } from "react-router-dom";

import { LocationDescriptor } from "history";
import WelcomeSection from "./components/WelcomeSection";
import ClientSection from "./components/ClientSection";
import FAQSection from "./components/FAQSection";
import PurposeSection from "./components/PurposeSection";
import SolutionsSection from "./components/SolutionsSection";
import SubscriptionSection from "./components/SubscriptionSection";
import LoaderScreen from "../LoaderScreen";

import { IPageConfigResponseModel } from "../../types/api";

import { fetchWebSiteContent, webSiteInfo } from "../../payload/data";
import { addPageConfigI18nTranslations, overridePageConfig } from "../../payload/partner";
import NavigationTemplate from "../../templates/NavigationTemplate";
import { createNavigationLinks } from "../../payload/navigation";

export default function Main() {
  const [content, setContent] = React.useState<IPageConfigResponseModel | null>(null);
  const history = useHistory<
    | {
        config?: IPageConfigResponseModel | undefined;
        message?: string | undefined;
      }
    | undefined
  >();

  const fetchMainWebSiteContent = () => {
    fetchWebSiteContent(webSiteInfo.bizpik.id)
      .then(data => {
        setContent(overridePageConfig(data, webSiteInfo.bizpik.id));
        addPageConfigI18nTranslations(data.translations);
      })
      .catch(error => {
        let to: LocationDescriptor<{ message: string }> | null = null;

        if (typeof error === "string")
          to = {
            pathname: "/error",
            state: {
              message: error
            }
          };
        else
          to = {
            pathname: "/error",
            state: {
              message: "Falha na conexão"
            }
          };

        history.replace(to);
      });
  };

  React.useEffect(() => {
    const { state } = history.location;

    if (state && state.config) {
      setContent(state.config);
      addPageConfigI18nTranslations(state.config.translations);
    } else {
      fetchMainWebSiteContent();
    }
  }, []);

  if (!content) {
    return <LoaderScreen logoUrl={webSiteInfo.bizpik.loadingImage} />;
  }

  return (
    <NavigationTemplate
      links={createNavigationLinks(webSiteInfo.bizpik.path, {
        clientsLinkVisible: content.client.companies.length > 0
      })}
    >
      <WelcomeSection origins={content.origins} comments={content.comments} />
      <SolutionsSection
        origins={content.origins}
        aiCRM={content.solutions.aiCRM}
        aiCommerce={content.solutions.aiCommerce}
        aiChat={content.solutions.aiChat}
      />
      {content.client.companies.length > 0 && (
        <ClientSection companies={content.client.companies} title={content.client.title} />
      )}
      <PurposeSection />
      <SubscriptionSection plans={content.plans} />
      <FAQSection />
    </NavigationTemplate>
  );
}
