/* eslint-disable no-param-reassign */
import { INavigationLink, INavigationOptions } from "../types/nav";

const defaultNavigationLinksOptions: INavigationOptions = {
  clientsLinkVisible: true,
  faqLinkVisible: true,
  homepageLinkVisible: true,
  purposeLinkVisible: true,
  solutionsLinkVisible: true,
  subsLinkVisible: true,
  producerLinkVisible: false
};

export const createNavigationLinks = (
  path: string,
  options: Partial<INavigationOptions> = defaultNavigationLinksOptions
) => {
  options = {
    ...defaultNavigationLinksOptions,
    ...options
  };

  const links: INavigationLink[] = [
    {
      path,
      anchor: "homepage",
      label: "nav:start"
    },
    {
      path,
      anchor: "solutions",
      label: "nav:solutions"
    },
    {
      path,
      anchor: "clients",
      label: "nav:clients"
    },
    {
      path,
      anchor: "purpose",
      label: "nav:purpose"
    },
    {
      path,
      anchor: "subs",
      label: "nav:subs"
    },
    {
      path,
      anchor: "faq",
      label: "nav:faq"
    },
    {
      path,
      anchor: "producers",
      label: "nav:producers"
    }
  ];

  return links.filter(x => {
    if (x.anchor === "homepage" && !options.homepageLinkVisible) return false;
    if (x.anchor === "solutions" && !options.solutionsLinkVisible) return false;
    if (x.anchor === "clients" && !options.clientsLinkVisible) return false;
    if (x.anchor === "purpose" && !options.purposeLinkVisible) return false;
    if (x.anchor === "subs" && !options.subsLinkVisible) return false;
    if (x.anchor === "faq" && !options.faqLinkVisible) return false;

    return true;
  });
};
