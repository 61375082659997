/* eslint-disable react/jsx-props-no-spreading */
import _ from "lodash";
import { Grid, GridProps } from "@mui/material";
import React from "react";
import { mergeMUISxProps } from "../utils/material";

interface GridContainerProps {
  childrenProps?: Partial<{ muiGrid: GridProps }>;
}

export default function GridContainer({
  childrenProps,
  children
}: React.PropsWithChildren<GridContainerProps>) {
  return (
    <Grid
      container
      sx={mergeMUISxProps(
        theme => ({
          position: "relative",
          paddingTop: 10,
          paddingBottom: 10,
          maxWidth: "1440px",
          margin: "0 auto",
          justifyContent: "center",

          [theme.breakpoints.down("sm")]: {
            paddingLeft: 4,
            paddingRight: 4
          }
        }),
        childrenProps?.muiGrid?.sx
      )}
      {...(_.omit(childrenProps?.muiGrid, "sx") ?? {})}
      children={children}
    />
  );
}
