import React from "react";
import { ListItemButton, MenuItem, Theme } from "@mui/material";
import LinkButtonComponent from "../../../components/LinkButtonComponent";

interface MenuLinkButtonProps {
  href: string;
  anchor?: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
}

const ListItemButtonAsAny = ListItemButton as any;

export default function MenuLinkButton({
  className,
  children,
  onClick,
  href,
  anchor
}: React.PropsWithChildren<MenuLinkButtonProps>) {
  return (
    <MenuItem className="p-0">
      <ListItemButtonAsAny
        sx={[
          {
            fontWeight: (theme: Theme) => theme.typography.fontWeightBold,
            color: (theme: Theme) => theme.palette.text.primary,
            lineHeight: "2rem",
            paddingX: 3
          }
        ]}
        onClick={onClick}
        className={className}
        disableTouchRipple
      >
        <LinkButtonComponent href={href} anchor={anchor ?? "#"} children={children} />
      </ListItemButtonAsAny>
    </MenuItem>
  );
}
