/* eslint-disable react/no-danger */
import React from "react";
import { Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import { IPageConfigProducerPersonSocialMedia } from "../types/page";
import LinkButton from "./LinkButton";

interface ProducerPersonCardProps {
  name: string;
  image: string;
  socialMedias: (IPageConfigProducerPersonSocialMedia & { image: string })[];
}

export default function ProducerPersonCard({
  name,
  image,
  socialMedias
}: ProducerPersonCardProps) {
  return (
    <Card className="min-w-[180px]">
      <CardMedia className="h-[180px]" image={image} title={name} />

      <CardContent>
        <Typography
          variant="h4"
          fontWeight={700}
          fontSize={24}
          color="primary.main"
          textAlign="center"
          className="line-clamp-2"
          children={
            <span dangerouslySetInnerHTML={{ __html: name.split(" ").join("<br />") }} />
          }
        />
      </CardContent>

      <CardActions classes={{ spacing: "gap-2" }} className="justify-center pb-4">
        {socialMedias.map(socialMedia => (
          <LinkButton key={socialMedia.name} href={socialMedia.url} newTab>
            <img width="28" height="28" src={socialMedia.image} alt={socialMedia.name} />
          </LinkButton>
        ))}
      </CardActions>
    </Card>
  );
}
