/* eslint-disable prettier/prettier */
import { Typography } from "@mui/material";

function PriceAndPayment() {
  return (
    <Typography>
      <strong>7. Preço e pagamento</strong>
      <br />
      <strong>7.1.</strong> Remuneração. O CLIENTE deverá pagar em cartão de crédito: todos os
      valores proporcionais aos pacotes de serviços escolhidos ou
      qualquer documento ou proposta relacionado a este contrato,
      considerando e não se limitando a (i) a remuneração é baseada nos Produtos SaaS adquiridos de forma pró rata e não na sua real
      utilização; (ii) os valores pagos não são restituíveis. O cálculo
      para pagamento das contas é baseado em períodos mensais,
      iniciando-se na data da contratação e cobrada no mesmo dia dos
      meses subsequentes.
      <br />
      <br />
      <strong>7.2.</strong> Faturamento e Pagamento. A remuneração será faturada após o
      pagamento. O CLIENTE é responsável por prover informações de
      faturamento e de contato precisas e completas e notificar o BIZPIK
      acerca de qualquer alteração das mesmas.
      <br />
      <br />
      <strong>7.3.</strong> Tributos. Em caso de aumento de alíquota dos tributos incidentes
      sobre o serviço contratado ou da imposição de novos tributos
      relativos a ele, o valor acrescido será repassado de imediato ao
      preço do serviço, com o que concorda o CLIENTE.
      <br />
      <br />
      <strong>7.4.</strong> O CLIENTE declara-se ciente que o BIZPIK é autorizada pelo
      WhatsApp, Facebook e Instagram a disponibilizar os seus produtos
      através das funcionalidades da Plataforma, sendo certo que estes
      estabelecem, a seu exclusivo critério, as políticas de uso dos
      produtos, preços praticados, continuidade e descontinuidade de
      produtos, entre outros. Dessa forma, em caso de quaisquer
      alterações, incluindo, mas não se limitando ao preço praticado, o
      BIZPIK realizará o imediato repasse das alterações ao CLIENTE,
      independente do plano contratado.
      <br />
      <br />
      <strong>7.5.</strong> Atraso no pagamento. Em caso de atraso no pagamento, o Cliente
      ficará sujeito ao pagamento das penalidades previstas na Cláusula
      11.2. Adicionalmente, o BIZPIK se reserva o direito de cancelar ou
      interromper o acesso à Plataforma do Cliente inadimplente, sem a
      necessidade de prévio aviso. Eventual tolerância do BIZPIK em
      relação ao Cliente inadimplente não retira ou limita o direito do
      BIZPIK de suspender e cancelar o acesso do Cliente à Plataforma a
      qualquer momento.
      <br />
      <br />
    </Typography>

  );
}

export default PriceAndPayment;
