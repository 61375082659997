import React from "react";

export default function NotFound() {
  return (
    <main className="w-screen h-screen flex flex-col items-center justify-center">
      <img src="/assets/bizpik-icon-logo.svg" alt="bizpik logo" />
      <h1 style={{ fontSize: 24 }} className="mt-8 mb-2">
        Página não encontrada.
      </h1>
    </main>
  );
}
