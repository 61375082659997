/* eslint-disable prettier/prettier */
import { Typography } from "@mui/material";

function Confidentiality() {
    return (
        <Typography>
            <strong>12. Confidencialidade</strong>
            <br />
            <strong>12.1.</strong> Definição de Informação Confidencial. Conforme utilizada no
            presente “Informação Confidencial”, significa todas as informações
            confidenciais fornecidas por uma parte (“Parte Reveladora”) à
            outra parte (“Parte Receptora”), oralmente ou por escrito, que
            esteja assinalada como confidencial, ou que razoavelmente possa
            ser compreendida como confidencial em razão de sua natureza e as
            circunstâncias de sua revelação. As Informações Confidenciais do CLIENTE deverão abarcar os dados do CLIENTE; Informações
            Confidenciais do BIZPIK deverão abarcar a Plataforma de SaaS; e
            Informações Confidenciais de cada parte deverão incluir os termos
            e condições deste contrato, incluindo planos de negócio e
            marketing, informações técnicas e de tecnologia, projetos de
            produtos e design, bem como procedimentos comerciais, revelados
            pela Parte Reveladora. Contudo, as Informações Confidenciais (que
            não os dados do CLIENTE), não deverão incluir qualquer informação
            que (i) for ou tornar-se de domínio público sem o descumprimento
            de qualquer obrigação para com a Parte Reveladora, (ii) for de
            conhecimento da Parte Receptora anteriormente à sua divulgação
            pela Parte Reveladora, sem o descumprimento de qualquer obrigação
            devida à esta, (iii) for recebida de um terceiro sem o
            descumprimento de qualquer dever para com a Parte Reveladora, ou,
            (iv) for desenvolvida de forma independente pela Parte Receptora.
            <br />
            <br />

            <strong>12.2.</strong> Proteção das Informações Confidenciais. A Parte Receptora deverá
            utilizar-se do mesmo nível de cuidado que utiliza para proteger
            suas próprias informações confidenciais, tal como, salvo se
            expressamente autorizado pela Parte Reveladora por escrito,
            limitar o acesso das Informações Confidenciais da Parte Reveladora
            para seus empregados e de suas afiliadas, contratados e agentes,
            que necessitem de tal acesso para executar o objeto deste
            Contrato, e que tenham firmado os respectivos termos de
            confidencialidade com a Parte Receptora que contenha previsões não
            menos rígidas do que as contidas no presente instrumento.
            <br />
            <br />

            <strong>12.3.</strong> Revelação Compulsória. A Parte Receptora poderá revelar as
            Informações Confidenciais da Parte Reveladora se for legalmente
            compelida a fazê-lo. Se a Parte Receptora for compelida por lei a
            revelar as Informações Confidenciais da Parte Reveladora em razão
            de processo judicial do qual a Parte Reveladora seja parte, e esta
            não contestar a divulgação, reembolsará a Parte Receptora pelos custos razoavelmente despendidos para compilar e fornecer
            acesso seguro a tais informações em observância à ordem judicial.

            <br />
            <br />
        </Typography>
    );
}

export default Confidentiality;
