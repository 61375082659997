import React from "react";
import { Box, Grid, Hidden, Typography } from "@mui/material";

import GridContainer from "../../../components/GridContainer";
import Translate from "../../../components/Translate";
import colors from "../../../themes/colors";

export default function PurposeSection() {
  return (
    <Box component="section">
      <GridContainer>
        <div className="absolute -top-16 md:-top-40 h-0 w-0" id="purpose" />

        <Grid container>
          <Hidden>
            <Grid item xs={1} />
          </Hidden>

          <Grid item xs={12} sm={10} md={4}>
            <Box className="max-w-[28rem]">
              <Typography
                variant="button"
                color={theme => theme.palette.text.secondary}
                children={<Translate i18nKey="section:purpose:subtitle" />}
              />

              <Typography
                color={colors.dodgerBlue}
                sx={{ fontWeight: 700, fontSize: 40 }}
                children={<Translate i18nKey="section:purpose:title" />}
                className="mb-12"
              />

              <Typography className="mb-2 font-normal" fontSize={16} variant="inherit">
                <Translate i18nKey="section:purpose:breathing" />
              </Typography>

              <Typography
                className="font-bold border-l-4 border-solid pl-6 mt-12"
                sx={{ borderColor: colors.guppieGreen, lineHeight: 1.25 }}
                fontSize={16}
                variant="inherit"
              >
                <Translate i18nKey="section:purpose:footnote" />
              </Typography>
            </Box>
          </Grid>

          <Hidden mdUp>
            <Grid item md={1} />
          </Hidden>

          <Hidden mdDown>
            <Grid item className="relative" md={7}>
              <img
                className="absolute top-1/2 -translate-y-1/2 -right-16 lg:translate-y-0 lg:-top-8"
                src="/assets/pc-view.png"
                alt="pc-view"
              />
            </Grid>
          </Hidden>
        </Grid>
      </GridContainer>
    </Box>
  );
}
