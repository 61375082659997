/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/ban-types */
import { TFuncKey } from "i18next";
import React from "react";
import { Trans, TransProps } from "react-i18next";
import useAppTranslations from "../hooks/useAppTranslations";

interface TranslateCustomProps<TInterpolationMap = {}> {
  i18nKey?: TFuncKey | TFuncKey[];
  customKey?: string | string[];
  values?: TInterpolationMap;
  components?:
    | readonly React.ReactElement[]
    | { readonly [tagName: string]: React.ReactElement };
}

type TranslateProps<TInterpolationMap> = Omit<
  TransProps<TFuncKey>,
  keyof TranslateCustomProps<TInterpolationMap>
> &
  TranslateCustomProps<TInterpolationMap>;

export default function Translate<TInterpolationMap>({
  i18nKey,
  customKey,
  components,
  values,
  ...rest
}: TranslateProps<TInterpolationMap>) {
  const { i18n, ns } = useAppTranslations();

  return (
    <Trans
      {...rest}
      components={components}
      values={values as {}}
      i18n={i18n}
      ns={ns}
      i18nKey={(i18nKey || customKey) as any}
    />
  );
}
