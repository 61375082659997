import { createTheme } from "@mui/material";

const darkTheme = createTheme({
  palette: {
    background: {
      default: "#1A1D22"
    },

    divider: "#3A3E43",

    text: {
      primary: "#CED4DA",
      secondary: "#7C8593"
    }
  },

  breakpoints: {
    values: {
      // extra-small
      xs: 0,
      // small
      sm: 600,
      // medium
      md: 900,
      // large
      lg: 1200,
      // extra-large
      xl: 1440
    }
  }
});

export default darkTheme;
