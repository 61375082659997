import { Box, styled } from "@mui/material";

const ChatBubbleContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(2),
  borderRadius: ".375rem",
  borderTopLeftRadius: 0,
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.grey[400],

  "::before": {
    content: "''",
    top: "-.5rem",
    width: ".5rem",
    height: ".5rem",
    left: 0,
    position: "absolute",
    borderRight: ".5rem solid transparent",
    borderBottom: `.5rem solid ${theme.palette.grey[200]}`,
    borderTopLeftRadius: "6px"
  }
}));

export default ChatBubbleContainer;
